import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { Link } from 'react-router-dom';
import paths, { rootPaths } from 'routes/paths';

import darkTheme from '../../assets/img/icons/menu-icons/dark-theme.svg';
import lineGraph from '../../assets/img/icons/menu-icons/line-graph.svg';
import userCard from '../../assets/img/icons/menu-icons/user-card.svg';
import greenUser from '../../assets/img/icons/menu-icons/new-user-green.svg';
import lifeRing from '../../assets/img/icons/menu-icons/life-ring.svg';
import messageIcon from '../../assets/img/icons/menu-icons/messages-icons.svg';

import '../../assets/scss/theme/page-styles/_dashboard.scss';
import '../../assets/scss/theme/helpers/_card-backgrounds.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faComments } from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
  const { changeTheme, config } = useAppContext();

  const toggleTheme = () => {
    const newTheme = config.theme === 'dark' ? 'light' : 'dark';
    changeTheme(newTheme);
  }

  const handleClick = (event, item) => {
    if (item.id == 2) {
      event.preventDefault();
      toggleTheme();
    }
  };

  const gridItems = [
    { id: 1, title: 'Simulator', description: 'Generate a financial risk analysis', icon: lineGraph, link: paths.impactSimulator },
    { id: 2, title: 'Light/Dark Mode', description: 'Adjust for optimal viewing', icon: darkTheme, link: '#' },
    { id: 3, title: 'New Client', description: 'Add new clients quickly', icon: greenUser, link: `${paths.addClient}?prospect=false` },
    { id: 4, title: 'New Prospect', description: 'Nurture prospects into future clients', icon: userCard, link: `${paths.addClient}?prospect=true` },
  ];

  return (
    <Container fluid className="py-4 px-0">
      <Row className="mb-4">
        <Col xs={12} md={7}>
          <Card className="bg-corner-2-card welcome-card">
            <Card.Body>
              <h2 className="m-0">
                <span className="text-primary">Welcome to </span>
                <span className="text-primary fw-bold">THREAT</span><span className="text-lighter-primary">CAPTAIN</span>
                <span className="text-primary">!</span>
              </h2>
              <p className="mt-1 mb-0 fw-bold text-secondary">
                Here are some quick links for you to start
              </p>

              <div className="grid-container dashboard-grid">
                {gridItems.map(item => (
                  <a key={item.id} className="grid-item" href={item.link} onClick={(e) => handleClick(e, item)}>
                    <img src={item.icon} alt={`Icon for ${item.title}`} className="item-icon" />
                    <div className="item-text">
                      <h5>{item.title}</h5>
                      <p className="text-secondary">{item.description}</p>
                    </div>
                  </a>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={5}>
          <Card className="mt-4 mt-md-0">
            <Card.Body className="dashboard-info-card">
              <h3 className="mb-3">Getting Started</h3>

              <div>
                <h5 className="fw-bold">Explore Key Features</h5>
                <ul className="custom-list">
                  <li>
                    <div className="d-flex align-items-start">
                      <FontAwesomeIcon
                        className="fa-icon mt-1 me-3"
                        icon={faCheck}
                        style={{ color: '#00D27A' }}
                      />
                      <div>
                        <p className="mb-0">
                          <span className="fw-bold">Feature 1</span> - Simulator
                        </p>
                        <p className="mb-0">
                          Find it under the quick links or the "Simulator" tab
                          on the navigation bar.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-start">
                      <FontAwesomeIcon
                        className="fa-icon mt-1 me-3"
                        icon={faCheck}
                        style={{ color: '#00D27A' }}
                      />
                      <div>
                        <p className='mb-0'><span className='fw-bold'>Feature 2</span> - Manage your Accounts</p>
                        <p className='mb-0'>
                          Access this feature under the “Organizations” tab on the navigation bar. You can also create new clients or prospects through the quick links.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                <h5 className="fw-bold">Need Help?</h5>
                <div className="d-flex align-items-start get-started-section-container">
                  <img src={lifeRing} alt="Logo 2" className="inline-logo" />
                  <div>
                    <p className="mb-0 fw-bold">Contact our Support Team:</p>
                    <p className="mb-0">ahoy@threatcaptain.com</p>
                  </div>
                </div>
              </div>

              <div>
                <h5 className="fw-bold">We Value Your Feedback!</h5>
                <div className="d-flex align-items-start get-started-section-container">
                  <p>
                    <img
                      src={messageIcon}
                      alt="Logo 2"
                      className="inline-logo"
                    />{' '}
                    Share your thoughts on features, navigation, or your overall
                    experience. Your feedback drives our evolution.
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-auto">
                <Button
                  as={Link}
                  to={rootPaths.feedback}
                  className="btn-lg w-100"
                  style={{ backgroundColor: '#00D27A', borderColor: '#00D27A' }}
                  onMouseOver={e => {
                    e.currentTarget.style.boxShadow =
                      '0 0 10px rgba(0, 0, 0, 0.2)';
                  }}
                  onMouseOut={e => {
                    e.currentTarget.style.boxShadow = 'none';
                  }}
                >
                  <FontAwesomeIcon
                    className="fa-icon mt-1 me-3"
                    icon={faComments}
                  />
                  Give Feedback
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Dashboard;
