import React from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { useAppContext } from 'providers/AppProvider';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const tooltipFormatter = params => {
  return (
    `<strong>${params.name}</strong>: ` +
    params.value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })
  );
};

const getOptions = (getThemeColor, riskDelta) => ({
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    textStyle: { color: getThemeColor('gray-1100') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },
  xAxis: {
    type: 'category',
    name: 'Risk Delta Net of Cost', // Updated x-axis name
    nameLocation: 'middle',
    nameGap: 60,
    nameTextStyle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: getThemeColor('gray-600')
    },
    data: [
      'IAM',
      'TI',
      'CTM',
      'IRM',
      'WF',
      'NIP',
      'GRC',
      'EDR',
      'SA',
      'UAT',
      'CM'
    ],
    axisLabel: {
      show: true,
      rotate: 45,
      margin: 10,
      color: getThemeColor('gray-400'),
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: function (value) {
        return value.toLocaleString(); // Format with commas
      },
      color: getThemeColor('gray-400'),
      margin: 15 // Margin to prevent label overlap
    }
  },
  series: [
    {
      name: 'Security Control Implementation %',
      type: 'bar',
      data: [
        {
          value: riskDelta?.IAM ?? 0,
          itemStyle: {
            color: '#086CD9',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.TI ?? 0,
          itemStyle: {
            color: '#7BBDEC',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.ContentManagement ?? 0,
          itemStyle: {
            color: '#2A9D90',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.IRM ?? 0,
          itemStyle: {
            color: '#E3F5FF',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.WF ?? 0,
          itemStyle: {
            color: '#E5ECF6',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.NIP ?? 0,
          itemStyle: {
            color: '#C6C7F8',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.GRC ?? 0,
          itemStyle: {
            color: '#A1E3CB',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.EDR ?? 0,
          itemStyle: {
            color: '#A8C5DA',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.SecureArch ?? 0,
          itemStyle: {
            color: '#95A4FC',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.UAT ?? 0,
          itemStyle: {
            color: '#BAEDBD',
            borderRadius: [5, 5, 0, 0]
          }
        },
        {
          value: riskDelta?.CM ?? 0,
          itemStyle: {
            color: '#718EBF',
            borderRadius: [5, 5, 0, 0]
          }
        }
      ]
    }
  ],
  grid: {
    top: '10%', // Added some space at the top to make the top label visible
    bottom: '100px',
    left: '10%',
    right: '10%'
  }
});

const LikelihoodChart = ({ riskDelta }) => {
  const { getThemeColor } = useAppContext();
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(getThemeColor, riskDelta)}
      style={{ height: '45rem' }}
    />
  );
};

LikelihoodChart.propTypes = {
  riskDelta: PropTypes.object
};

export default LikelihoodChart;
