const securityPostureCalculator = (intakeForm) => {
    const policyScoreValue = (targetVal) => {
        if (targetVal == 3) return 4.7;
        if (targetVal == 2) return 2.82;
        if (targetVal == 1) return 1.41;
        if (targetVal == 0) return 0;
    }

    const getCyberPolicyScore = () => {
        var score = 0;

        if (intakeForm?.pepp) score += policyScoreValue(intakeForm.pepp);

        if (intakeForm?.ipppp) score += policyScoreValue(intakeForm.ipppp);

        if (intakeForm?.cmp) score += policyScoreValue(intakeForm.cmp);

        if (intakeForm?.aap) score += policyScoreValue(intakeForm.aap);

        if (intakeForm?.pmp) score += policyScoreValue(intakeForm.pmp);

        if (intakeForm?.saap) score += policyScoreValue(intakeForm.saap);

        if (intakeForm?.bep) score += policyScoreValue(intakeForm.bep);

        if (intakeForm?.rap) score += policyScoreValue(intakeForm.rap);

        if (intakeForm?.iap) score += policyScoreValue(intakeForm.iap);

        if (intakeForm?.siip) score += policyScoreValue(intakeForm.siip);

        if (intakeForm?.mpp) score += policyScoreValue(intakeForm.mpp);

        if (intakeForm?.pp) score += policyScoreValue(intakeForm.pp);

        if (intakeForm?.gp) score += policyScoreValue(intakeForm.gp);

        if (intakeForm?.atp) score += policyScoreValue(intakeForm.atp);

        if (intakeForm?.acp) score += policyScoreValue(intakeForm.acp);

        if (intakeForm?.scpp) score += policyScoreValue(intakeForm.scpp);

        if (intakeForm?.cpp) score += policyScoreValue(intakeForm.cpp);

        if (intakeForm?.psp) score += policyScoreValue(intakeForm.psp);

        if (intakeForm?.scrm) score += policyScoreValue(intakeForm.scrm);

        if (intakeForm?.ip) score += policyScoreValue(intakeForm.ip);

        if (intakeForm?.irp) score += policyScoreValue(intakeForm.irp);

      return Math.ceil(score);
    }

    const findTrainingValue = (formValue) => {
        if (formValue === "Multiple times per year") {
            return 33.3;
        } else if (formValue === "Once per year") {
            return 16.65;
        } else {
            return 0;
        }
    }

    const getTrainingScoresValue = () => {
        var score = 0;

        if (intakeForm === null) {
            return 0;
        } else {
            score += findTrainingValue(intakeForm.incidentResponse);
            score += findTrainingValue(intakeForm.disasterRecovery);
            score += findTrainingValue(intakeForm.tabletop);
        }

        return Math.ceil(score);
    }

    const getLeadershipScore = () => {
        var score = 0;

        if (intakeForm === null) {
            return 0;
        } else if (intakeForm.securityTeam === "We have a dedicated security team with a CISO") {
            score += 100;
        } else if (intakeForm.securityTeam === "We have a dedicated security team that reports into Information Technology") {
            score += 50;
        } else {
            score += 0;
        }

        if (intakeForm.disconnect) {
            score -= 20;
        }
        if (intakeForm.fear) {
            score -= 20;
        }
        if (intakeForm.hopeless) {
            score -= 20;
        }
        if (intakeForm.nonInclusion) {
            score -= 20;
        }
        if (intakeForm.businessFocus) {
            score -= 20;
        }

        if (score < 0) {
            score = 0;
        }

        return score;
    }

    const getComplianceScore = (score1, score2, score3) => {
        return Math.ceil(((score1 + score2 + score3) / 3))
    }

    const securityScoreValue = (targetVal) => {
        if (targetVal == 2) return 9;
        if (targetVal == 1) return 4.5;
        if (targetVal == 0) return 0;
    }

    const getSecurityControlsScore = () => {
        var score = 0;

        if (intakeForm?.iam) {
            score += securityScoreValue(intakeForm.iam);
        }

        if (intakeForm?.cm) {
            score += securityScoreValue(intakeForm.cm);
        }

        if (intakeForm?.uat) {
            score += securityScoreValue(intakeForm.uat);
        }

        if (intakeForm?.secureArch) {
            score += securityScoreValue(intakeForm.secureArch);
        }

        if (intakeForm?.edr) {
            score += securityScoreValue(intakeForm.edr);
        }

        if (intakeForm?.grc) {
            score += securityScoreValue(intakeForm.grc);
        }

        if (intakeForm?.nip) {
            score += securityScoreValue(intakeForm.nip);
        }

        if (intakeForm?.wf) {
            score += securityScoreValue(intakeForm.wf);
        }

        if (intakeForm?.irm) {
            score += securityScoreValue(intakeForm.irm);
        }

        if (intakeForm?.cm) {
            score += securityScoreValue(intakeForm.contentManagement);
        }

        if (intakeForm?.ti) {
            score += securityScoreValue(intakeForm.ti);
        }

        return Math.ceil(score);
    }

    const getRiskMitigationDocumentationScore = () => {
        var score = 0;

        if (intakeForm?.bcplan) {
            score += 16.6;
        }
        if (intakeForm?.irplan) {
            score += 16.6;
        }
        if (intakeForm?.drplan) {
            score += 16.6;
        }
        if (intakeForm?.isia) {
            score += 16.6;
        }
        if (intakeForm?.cmplan) {
            score += 16.6;
        }
        if (intakeForm?.parm) {
            score += 16.6;
        }

        return Math.ceil(score);
    }

    const cyberPolicyScore = getCyberPolicyScore();
    const trainingScoresValue = getTrainingScoresValue();
    const leadershipScore = getLeadershipScore();
    const securityControlsScore = getSecurityControlsScore();
    const riskMitigationDocumentationScore = getRiskMitigationDocumentationScore();
    const complianceScore = getComplianceScore(cyberPolicyScore, riskMitigationDocumentationScore, securityControlsScore);

    const securityPostureValues = {
        cyberPolicy: cyberPolicyScore,
        trainingScores: trainingScoresValue,
        leadership: leadershipScore,
        compliance: complianceScore,
        securityControls: securityControlsScore,
        riskMitigationDocumentation: riskMitigationDocumentationScore
    };
    console.log('This is the security posture');
    console.log(securityPostureValues.cyberPolicy);
    console.log(securityPostureValues.compliance);
    console.log(securityPostureValues.securityControls);

    return [
        securityPostureValues.cyberPolicy,
        securityPostureValues.trainingScores,
        securityPostureValues.leadership,
        securityPostureValues.compliance,
        securityPostureValues.securityControls,
        securityPostureValues.riskMitigationDocumentation
    ];
};

export default securityPostureCalculator;