import { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthContext } from 'components/auth0/AuthContext';
import { toast } from 'react-toastify';

const useMsp = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [mspDetails, setMspDetails] = useState(null);
  const [error, setError] = useState(null);
  const authUser = useContext(AuthContext);
  const mspId = authUser?.msp?.id;

  const fetchMspDetails = async () => {
    if (!mspId) {
      console.error('MSP ID is not available');
      setError('MSP ID is not available');
      return null;
    }

    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/msp/${mspId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setMspDetails(data);
      return data;

    } catch (error) {
      console.error('Error fetching MSP details:', error);
      setError(error.message);
      return null;
    }
  };

  const updateMspDetails = async (updatedMsp) => {
    if (!mspId) {
      console.error('MSP ID is not available');
      setError('MSP ID is not available');
      return null;
    }

    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/msp/${mspId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(updatedMsp)
        }
      );

      if (!response.ok) {
        toast.error('Failed to update MSP info.');
      }
      else {
        toast.success('MSP info updated successfully!');
      }

      const data = await response.json();
      setMspDetails(data);
      return data;

    } catch (error) {
      console.error('Error updating MSP details:', error);
      setError(error.message);
      return null;
    }
  };

  useEffect(() => {
    if (mspId) {
      fetchMspDetails();
    }
  }, [mspId]);

  return { fetchMspDetails, updateMspDetails, mspDetails, error };
};

export default useMsp;
