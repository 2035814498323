import React from 'react';
import { Alert } from 'react-bootstrap';

const FlashMessage = ({ message, onClose }) => (
  <Alert variant="danger" onClose={onClose} dismissible>
    {message}
  </Alert>
);

export default FlashMessage;
