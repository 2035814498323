import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const IntakeWizardContext = createContext({ user: {} });

const IntakeWizardProvider = ({ children }) => {
  const [intake, setIntake] = useState({});
  const [step, setStep] = useState(1);

  const value = { intake, setIntake, step, setStep };
  return (
    <IntakeWizardContext.Provider value={value}>
      {children}
    </IntakeWizardContext.Provider>
  );
};

IntakeWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useIntakeWizardContext = () => useContext(IntakeWizardContext);

export default IntakeWizardProvider;
