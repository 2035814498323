import React, { useState } from 'react';
import IconButton from 'components/common/IconButton';
import { Col, Row } from 'react-bootstrap';
import { Popover, OverlayTrigger, Button, Form } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import { useFilter } from '../ClientListContext';
import ClientListPopover from '../ClientListPopover';
import FlashMessage from 'components/common/FlashMessage'; // Assuming you have a FlashMessage component

const ClientListHeader = ({ rowCount, clientData }) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [flashMessage, setFlashMessage] = useState(null);

  const handleToggle = (show) => {
    setIsActive(show);
  }

  const handleNewClick = () => {
    navigate(paths.addClient);
    // const nonProspectCount = clientData.filter(client => !client.prospect).length;
    // if (nonProspectCount >= 5) {
    //   setFlashMessage("You can only register 5 clients with your subscription");
    // } else {
      
    // }
  };

  const popover = (
    <Popover id='filter-popover'>
      <Popover.Header as="h3">Filters</Popover.Header>
      <Popover.Body>
        <ClientListPopover />
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {flashMessage && <FlashMessage message={flashMessage} onClose={() => setFlashMessage(null)} />}
      <Row className="flex-between-center flex-column flex-sm-row">
        <Col sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-9 mb-0 text-nowrap py-2 pe-2 py-xl-0">All Accounts</h5>
          <Col xs="auto">
            <AdvanceTableSearchBox
              className="input-search-width"
              placeholder="Search by name"
            />
          </Col>
        </Col>
        <Col sm="auto" className="">
          <div id="orders-actions">
            <span onClick={handleNewClick}>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                className="mx-2"
                transform="shrink-3"
                style={{backgroundColor: '#2C7BE5', borderColor: '#2C7BE5', color: 'white'}}
              >
                <span className="ms-1">New</span>
              </IconButton>
            </span>
            <OverlayTrigger trigger="click" placement='bottom' overlay={popover} rootClose onToggle={handleToggle}>
              <span>
                <IconButton
                  variant={isActive ? 'falcon-primary' : 'falcon-default'}
                  size="sm"
                  icon="filter"
                  transform="shrink-3"
                  className="mx-2"
                >
                  <span className="ms-1">Filter</span>
                </IconButton>
              </span>
            </OverlayTrigger>
            {/* <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="ms-1">Export</span>
            </IconButton> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ClientListHeader;
