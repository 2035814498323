export const industries = [
  'Healthcare',
  'Financial',
  'Energy',
  'Pharmaceuticals',
  'Industrial',
  'Technology',
  'Education',
  'Services',
  'Entertainment',
  'Transportation',
  'Communication',
  'Consumer',
  'Media',
  'Hospitality',
  'Retail',
  'Research',
  'Public',
  'None'
];
export const countries = [
  'UnitedStates',
  'Germany',
  'Canada',
  'MiddleEast',
  'France',
  'Italy',
  'SouthAfrica',
  'UnitedKingdom',
  'Japan',
  'SouthKorea',
  'ASEAN',
  'Australia',
  'Turkey',
  'Scandinavia',
  'Brazil',
  'India',
  'Global'
];
export const breachTypes = [
  'Malicious',
  'AccidentalInsider',
  'Ransomware',
  'Destructionware',
  'Default'
];

export const generateBreachGraphData = impactResponse => {
  if (!impactResponse) return [];

  return [
    {
      value: Math.ceil(
        0.3938 * impactResponse.averageConfidentialityBreachCost
      ),
      name: 'Business Disruption'
    },
    {
      value: Math.ceil(
        0.2876 * impactResponse.averageConfidentialityBreachCost
      ),
      name: 'Detection & Escalation'
    },
    {
      value: Math.ceil(
        0.0622 * impactResponse.averageConfidentialityBreachCost
      ),
      name: 'Notification'
    },
    {
      value: Math.ceil(
        0.2565 * impactResponse.averageConfidentialityBreachCost
      ),
      name: 'Ex-Post'
    }
  ];
};

export const generateImpactStats = impactResponse => {
  if (!impactResponse) return impactStats;

  return [
    {
      title: `Industry`,
      amount: `${impactResponse.confidentialInformationBreach.myIndustry}`,
      className: 'border-200 border-bottom border-end pb-4'
    },
    {
      title: 'Breach Type',
      amount: `${impactResponse.confidentialInformationBreach.myBreachType}`,
      className:
        'border-200 border-md-200 border-bottom border-md-end pb-4 ps-1'
    },
    {
      title: 'Avg. Industy Breach Cost',
      amount: `${(
        impactResponse.averageConfidentialityBreachCost / 1000000
      ).toFixed(2)}M`,
      className:
        'border-200 border-md-200 border-bottom border-md-end pb-4 ps-1'
    },
    // {
    //   title: '% of Industy',
    //   amount: `0%`,
    //   className:
    //     'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3'
    // },
    {
      title: 'Estimated Records Lost',
      amount: `${impactResponse.averageConfidentialityBreachRecordsLost.toLocaleString()}`,
      className:
        'border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-1'
    }
  ];
};
const impactStats = [
  {
    title: `Industry`,
    amount: ``,
    className: 'border-200 border-bottom border-end pb-4'
  },
  {
    title: 'Breach Type',
    amount: '',
    className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3'
  },
  {
    title: 'Avg. Industry Breach Cost',
    amount: '0',
    className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3'
  },
  {
    title: 'Estimated Records Lost',
    amount: '0',
    className:
      'border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-3'
  }
];

export const generateBreachCostData = impactResponse => {
  if (!impactResponse) return [];

  return impactResponse.dataset;
};
