import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ClientModal = ({ show, setShow, handleClose }) => {
  return (
    <Modal show={show} onHide={() => setShow(!show)} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title className="danger">WARNING</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center fs-8">
        Leaving now will lose unsaved data. Continue?{' '}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-primary" onClick={handleClose}>
          Yes, I have saved the data
        </Button>
        <Button variant="falcon-danger" onClick={() => setShow(!show)}>
          No, stay on this page
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ClientModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ClientModal;
