import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import WizardInput from './WizardInput';
import ControlsRadioInput from './ControlsRadioInput';
import SecControlIcon from 'components/simulator/likelihood/SecControlIcon';
// Icons
import iam from 'assets/img/security-controls/iam.png';
import uat from 'assets/img/security-controls/uat.png';
import secArch from 'assets/img/security-controls/secArch.png';
import edr from 'assets/img/security-controls/edr.png';
import ti from 'assets/img/security-controls/ti.png';
import nip from 'assets/img/security-controls/nip.png';
import irm from 'assets/img/security-controls/irm.png';
import wf from 'assets/img/security-controls/wf.png';
import content from 'assets/img/security-controls/content.png';
import cm from 'assets/img/security-controls/cm.png';
import grc from 'assets/img/security-controls/grc.png';

//<SecControlIcon icon={iam} value={iamVals[0]} />

const ControlsForm = ({ register, errors, intake, setValue }) => {
  const [iamVals, setIamVals] = useState(0);
  const [configMgmtVals, setConfigMgmtVals] = useState(0);
  const [uatVals, setUatVals] = useState(0);
  const [secureArchVals, setSecureArchVals] = useState(0);
  const [edrVals, setEdrVals] = useState(0);
  const [grcVals, setGrcVals] = useState(0);
  const [nipVals, setNipVals] = useState(0);
  const [wfVals, setWfVals] = useState(0);
  const [irmVals, setIrmVals] = useState(0);
  const [contentMgmtVals, setContentMgmtVals] = useState(0);
  const [tiVals, setTiVals] = useState(0);

  useEffect(() => {
    if (intake) {
      setValue('iam', parseInt(intake.iam, 10) || 0);
      setValue('cm', parseInt(intake.cm, 10) || 0);
      setValue('uat', parseInt(intake.uat, 10) || 0);
      setValue('secureArch', parseInt(intake.secureArch, 10) || 0);
      setValue('edr', parseInt(intake.edr, 10) || 0);
      setValue('grc', parseInt(intake.grc, 10) || 0);
      setValue('nip', parseInt(intake.nip, 10) || 0);
      setValue('wf', parseInt(intake.wf, 10) || 0);
      setValue('irm', parseInt(intake.irm, 10) || 0);
      setValue('contentManagement', parseInt(intake.cm, 10) || 0);
      setValue('ti', parseInt(intake.ti, 10) || 0);
      setIamVals(getProcessValue(intake.iam));
      setConfigMgmtVals(getProcessValue(intake.cm));
      setUatVals(getProcessValue(intake.uat));
      setSecureArchVals(getProcessValue(intake.secureArch));
      setEdrVals(getProcessValue(intake.edr));
      setGrcVals(getProcessValue(intake.grc));
      setNipVals(getProcessValue(intake.nip));
      setWfVals(getProcessValue(intake.wf));
      setIrmVals(getProcessValue(intake.irm));
      setContentMgmtVals(getProcessValue(intake.contentManagement));
      setTiVals(getProcessValue(intake.ti));
    }
  }, [intake, setValue]);

  const radioOptions = [
    { value: 2, label: 'Fully Deployed' },
    { value: 1, label: 'Partially Deployed' },
    { value: 0, label: 'Not Deployed' }
  ];

  const getProcessValue = (value) => {
    if (value == 0) return 0;
    else if (value == 1) return 50;
    else return 100;
  }
  
  return (
    <>
      <h4>Security Controls</h4>
      <Row className="g-4 mb-3">
        <p>How are your controls deployed?</p>
      </Row>
      <Row className="g-4 mb-3">
        {/* column 1 */}
        <Col md={4}>
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={iam} value={iamVals} size={45} imgSize={35}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">
                Identity & Access Management
                <br />
                <b>(IAM)</b>
              </h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='iam'
              label="IAM"
              value={intake.iam || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('iam') }}
              setValue={setValue}
              setColor={setIamVals}
              setColorValue={getProcessValue}
            />
          </Row>
          <br />
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={edr} value={edrVals} size={45} imgSize={35}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">
                Endpoint Detection & Response
                <br />
                <b>(EDR)</b>
              </h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='edr'
              label="EDR"
              value={intake.edr || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('edr') }}
              setValue={setValue}
              setColor={setEdrVals}
              setColorValue={getProcessValue}
            />
          </Row>
          <br />
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={irm} value={irmVals} size={45} imgSize={25}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">
                Information Rights Management <b>(IRM)</b>
              </h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='irm'
              label="IRM"
              value={intake.irm || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('irm') }}
              setValue={setValue}
              setColor={setIrmVals}
              setColorValue={getProcessValue}
            />
          </Row>
          <br />
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={cm} value={configMgmtVals} size={45} imgSize={28}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">
                Configuration Management <b>(CM)</b>
              </h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='cm'
              label="CM"
              value={intake.cm || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('cm') }}
              setValue={setValue}
              setColor={setConfigMgmtVals}
              setColorValue={getProcessValue}
            />
          </Row>
        </Col>
        {/* column 2 */}
        <Col md={4}>
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={uat} value={uatVals} size={45} imgSize={35}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">
                User Awareness Testing <b>(UAT)</b>
              </h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='uat'
              label="UAT"
              value={intake.uat || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('uat') }}
              setValue={setValue}
              setColor={setUatVals}
              setColorValue={getProcessValue}
            />
          </Row>
          <br />
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={ti} value={tiVals} size={45} imgSize={35}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">
                Threat Intelligence <b>(TI)</b>
              </h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='ti'
              label="TI"
              value={intake.ti || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('ti') }}
              setValue={setValue}
              setColor={setTiVals}
              setColorValue={getProcessValue}
            />
          </Row>
          <br />
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={wf} value={wfVals} size={45} imgSize={30}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">
                Web Filtering <b>(WF)</b>
              </h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='wf'
              label="WF"
              value={intake.wf || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('wf') }}
              setValue={setValue}
              setColor={setWfVals}
              setColorValue={getProcessValue}
            />
          </Row>
          <br />
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={grc} value={grcVals} size={45} imgSize={30}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">
                Governance, Risk Management,
                <br /> & Compliance/Integrated Risk
                <br /> Management <b>(GRC)</b>
              </h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='grc'
              label="GRC"
              value={intake.grc || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('grc') }}
              setValue={setValue}
              setColor={setGrcVals}
              setColorValue={getProcessValue}
            />
          </Row>
        </Col>
        {/* column 3 */}
        <Col md={4}>
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={secArch} value={secureArchVals} size={45} imgSize={30}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">Secure Architecture <b>(SA)</b></h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='secureArch'
              label="Secure Architecture"
              value={intake.secureArch || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('secureArch') }}
              setValue={setValue}
              setColor={setSecureArchVals}
              setColorValue={getProcessValue}
            />
          </Row>
          <br />
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={nip} value={nipVals} size={45} imgSize={30}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">
                Network Intrusion Prevention
                <br />
                <b>(NIP)</b>
              </h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='nip'
              label="NIP"
              value={intake.nip || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('nip') }}
              setValue={setValue}
              setColor={setNipVals}
              setColorValue={getProcessValue}
            />
          </Row>
          <br />
          <Row>
            <Flex alignItems="center" className="mb-3">
              <span className="fa-stack fa-lg">
                <SecControlIcon icon={content} value={contentMgmtVals} size={45} imgSize={30}/>
              </span>
              <h5 className="fs-9 text-600 mb-0 ps-1">Content Management <b>(CTM)</b></h5>
            </Flex>
          </Row>
          <Row className="ps-7">
            <ControlsRadioInput 
              name='contentManagement'
              label="Content Management"
              value={intake.contentManagement || 0}
              options={radioOptions}
              errors={errors}
              formControlProps={{ ...register('contentManagement') }}
              setValue={setValue}
              setColor={setContentMgmtVals}
              setColorValue={getProcessValue}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

ControlsForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object
};

export default ControlsForm;
