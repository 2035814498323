import React from 'react';
import AccountSettings from 'components/accountSettings/AccountSettings';
import AccountSettingsProvider from 'providers/AccountSettingsProvider';

const AccountSettingsPage = () => {
  return (
    <AccountSettingsProvider>
      <AccountSettings/>
    </AccountSettingsProvider>
  );
};

export default AccountSettingsPage;
