import React, { useEffect, useState } from 'react';
import { Card, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; // Import useLocation hook

import { useAccountSettingsContext } from 'providers/AccountSettingsProvider';

import SecuritySettings from './SecuritySettings';
import GeneralSettings from './GeneralSettings';
import MspSettings from './MspSettings';
import UserManagement from './UserManagement';
import SupportSettings from './SupportSettings';

import useAccount from 'hooks/useAccount';

import useMsp from 'hooks/useMsp';

import 'assets/scss/theme/page-styles/_accountSettings.scss';

const SettingsDetailPage = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('general');
  const [mspDetails, setMspDetails] = useState(null);

  const { userInfo, setUserInfo } = useAccountSettingsContext();
  const { getAccount, updateAccount } = useAccount();
  const { fetchMspDetails } = useMsp();

  useEffect(() => {
    const loadUserInfo = async () => {
      await getAccount(setUserInfo);
    };

    loadUserInfo();
  }, []);

  useEffect(() => {
    // Fetch MSP details and set the state
    const loadMspDetails = async () => {
      const data = await fetchMspDetails();
      console.log('load msp details');
      console.log(data);
      setMspDetails(data);
    };
    if (userInfo?.isAdmin) {
      loadMspDetails();
    }
  }, [userInfo]);

  // Check the query parameter for the selected tab
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setSelectedTab(tab);
    }
  }, [location]);

  const onUpdateAccount = async data => {
    try {
      const statusCode = await updateAccount({
        ...userInfo,
        ...data
      });

      if (statusCode === 200) {
        setUserInfo({
          ...userInfo,
          ...data
        });
        return true;
      } else {
        console.error(`Update failed with status code: ${statusCode}`);
        return false;
      }
    } catch (error) {
      console.error('Error in onUpdateAccount:', error);
      return false;
    }
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'general':
        return (
          <GeneralSettings
            userInfo={userInfo}
            updateAccount={onUpdateAccount}
          />
        );

      case 'msp':
        return (
          <MspSettings mspDetails={mspDetails} setMspDetails={setMspDetails} />
        );

      case 'users':
        return <UserManagement userInfo={userInfo} />;

      case 'support':
        return <SupportSettings />;

      case 'security':
        return <SecuritySettings userInfo={userInfo} />;

      default:
        return <GeneralSettings />;
    }
  };

  return (
    <Card>
      <Card.Header>
        <Nav
          justify
          variant="tabs"
          activeKey={selectedTab}
          onSelect={selectedKey => setSelectedTab(selectedKey)}
        >
          <Nav.Item>
            <Nav.Link
              eventKey="general"
              style={{
                fontWeight: selectedTab === 'general' ? 'bold' : 'normal',
                marginRight: '20px'
              }}
            >
              {' '}
              General Information{' '}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="security"
              style={{
                fontWeight: selectedTab === 'security' ? 'bold' : 'normal',
                marginRight: '20px'
              }}
            >
              {' '}
              Security Settings{' '}
            </Nav.Link>
          </Nav.Item>
          {userInfo?.isAdmin && (
            <Nav.Item>
              <Nav.Link
                eventKey="msp"
                style={{
                  fontWeight: selectedTab === 'msp' ? 'bold' : 'normal',
                  marginRight: '20px'
                }}
              >
                {' '}
                MSP Management{' '}
              </Nav.Link>
            </Nav.Item>
          )}
          {userInfo?.isAdmin && (
            <Nav.Item>
              <Nav.Link
                eventKey="users"
                style={{
                  fontWeight: selectedTab === 'users' ? 'bold' : 'normal',
                  marginRight: '20px'
                }}
              >
                {' '}
                User Management{' '}
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link
              eventKey="support"
              style={{
                fontWeight: selectedTab === 'support' ? 'bold' : 'normal',
                marginRight: '20px'
              }}
            >
              {' '}
              Support{' '}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Card.Header>
      <Card.Body className="account-settings-card-body">
        {renderContent()}
      </Card.Body>
    </Card>
  );
};

export default SettingsDetailPage;
