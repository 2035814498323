import React from 'react';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import tcWhiteHat from 'assets/img/illustrations/tc-white-hat.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';

const Greetings = () => {
  return (
    <Card className="bg-100 shadow-none border mb-3">
      <Card.Body className="py-0">
        <Row className="g-0 justify-content-between">
          <Col sm="auto" className="m-4">
            <Flex alignItems="center">
              <img
                src={tcWhiteHat}
                width={90}
                alt="..."
                className="ms-n4 me-2"
              />
              <div>
                <h4 className="text-primary fw-bold mb-0">Select your plan</h4>
              </div>
              <img
                src={lineChart}
                width={140}
                alt="..."
                className="ms-n4 d-md-none d-lg-block"
              />
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Greetings;
