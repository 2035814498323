import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

const ControlDescriptionTooltip = ({
  id,
  children,
  size,
  placement = 'bottom'
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id={id} className="simulatorTooltip">
          {children}
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <span {...triggerHandler} ref={ref} className="ms-1">
          <BsFillQuestionCircleFill size={size} color="#7D7D7D" />
        </span>
      )}
    </OverlayTrigger>
  );
};

ControlDescriptionTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  placement: PropTypes.string
};

export default ControlDescriptionTooltip;
