import React from 'react';
import { useFilter } from './ClientListContext';
import { Button } from 'react-bootstrap';

const ClientListPopover = () => {
    const { filters, setFilters } = useFilter();

    const handleIndustryChange = (e) => {
        setFilters(prev => ({ ...prev, industry: e.target.value }));
    };

    const handleProspectChange = (e) => {
        const { value } = e.target;
        setFilters(prev => ({ ...prev, prospect: value === '' ? undefined : value === 'true' }));
    };

    const handleTimingChange = (e) => {
        const { value } = e.target;
        setFilters(prev => ({ ...prev, timing: value }));
    };

    const resetFilters = () => {
        setFilters({ industry: 'All', prospect: undefined, timing: 'All' });
    };

    return (
        <div className="popover-content">
            <form>
                <div className="mb-3">
                    <label htmlFor="industryFilter" className="form-label">Industry</label>
                    <select
                        id="industryFilter"
                        className="form-select"
                        value={filters.industry || 'All'}
                        onChange={handleIndustryChange}
                    >
                        <option value="All">All Industries</option> <option value="Healthcare">Healthcare</option>
                        <option value="Finance">Finance</option> <option value="Energy">Energy</option>
                        <option value="Pharmaceuticals">Pharmaceuticals</option> <option value="Industrial">Industrial</option>
                        <option value="Technology">Technology</option> <option value="Education">Education</option>
                        <option value="Services">Services</option> <option value="Entertainment">Entertainment</option>
                        <option value="Transportation">Transportation</option> <option value="Communication">Communication</option>
                        <option value="Consumer">Consumer</option> <option value="Media">Media</option>
                        <option value="Hospitality">Hospitality</option> <option value="Retail">Retail</option>
                        <option value="Research">Research</option> <option value="Public">Public</option>
                        <option value="None">None</option>
                        
                    </select>
                </div>
                
                <div className="mb-3">
                    <label htmlFor="prospectFilter" className="form-label">Account Type</label>
                    <div>
                        <input
                            type="radio"
                            id="prospectAll"
                            name="prospect"
                            value=""
                            checked={filters.prospect === undefined}
                            onChange={handleProspectChange}
                        />
                        <label htmlFor="prospectAll" className="ms-2">All</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="prospectTrue"
                            name="prospect"
                            value="true"
                            checked={filters.prospect === true}
                            onChange={handleProspectChange}
                        />
                        <label htmlFor="prospectTrue" className="ms-2">Prospect</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="prospectFalse"
                            name="prospect"
                            value="false"
                            checked={filters.prospect === false}
                            onChange={handleProspectChange}
                        />
                        <label htmlFor="prospectFalse" className="ms-2">Client</label>
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="timingFilter" className="form-label">Timing</label>
                    <div>
                        <input
                            type="radio"
                            id="timingAll"
                            name="timing"
                            value="All"
                            checked={filters.timing === 'All'}
                            onChange={handleTimingChange}
                        />
                        <label htmlFor="timingAll" className="ms-2">All</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="timing7Days"
                            name="timing"
                            value="7"
                            checked={filters.timing === '7'}
                            onChange={handleTimingChange}
                        />
                        <label htmlFor="timing7Days" className="ms-2">Last 7 Days</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="timing30Days"
                            name="timing"
                            value="30"
                            checked={filters.timing === '30'}
                            onChange={handleTimingChange}
                        />
                        <label htmlFor="timing30Days" className="ms-2">Last 30 Days</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="timing6Months"
                            name="timing"
                            value="180"
                            checked={filters.timing === '180'}
                            onChange={handleTimingChange}
                        />
                        <label htmlFor="timing6Months" className="ms-2">Last 6 Months</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="timing1Year"
                            name="timing"
                            value="365"
                            checked={filters.timing === '365'}
                            onChange={handleTimingChange}
                        />
                        <label htmlFor="timing30Days" className="ms-2">Last Year</label>
                    </div>
                </div>

                <Button variant='secondary' onClick={resetFilters} className='mt-1'>Reset Filters</Button>
            </form>
        </div>
    );
};

export default ClientListPopover;
