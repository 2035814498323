import React from 'react';
import GenericError from './GenericError';

const Error500 = () => (
  <GenericError code={500} title="Whoops, something went wrong!">
    Try refreshing the page, or going back and attempting the action again. If
    this problem persists,
    <a href="mailto:info@exmaple.com" className="ms-1">
      contact us
    </a>
    .
  </GenericError>
);

export default Error500;
