import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useSaveIntake = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const clientId = localStorage.getItem('clientId');

  const saveIntake = async data => {
    setIsLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/${clientId}/saveIntake`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(data)
        }
      );
      if (!response.ok) throw new Error('Failed to save client');
      const responseData = await response.json();
      setResponse(responseData);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { saveIntake, isLoading, error, response };
};
export default useSaveIntake;
