import { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthContext } from 'components/auth0/AuthContext';

const useClients = () => {
  const [clients, setClients] = useState({});
  const { getAccessTokenSilently } = useAuth0();
  //replace this with a local storage call
  const user = useContext(AuthContext);
  const mspId = user?.msp?.id;

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/simulator/impact/${mspId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setClients(data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    if (mspId) {
      fetchClients();
    }
  }, []);

  return clients;
};

export default useClients;
