import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const usePostLikelihood = () => {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const postLikelihood = async (
    LikelihoodSimFormData,
    simulationId,
    setLikelihoodResponse,
    force
  ) => {
    setLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      let url = `${process.env.REACT_APP_API_SERVER_URL}/simulator/likelihood?simulation_id=${simulationId}`;
      if (force) {
        url += '&force=true';
      }
      //this should go thorugh middleware instead of directly to this endpoint
      const response = await fetch(url, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(LikelihoodSimFormData)
      });
      if (!response.ok) {
        const error = new Error('Network response was not ok');
        error.status = response.status;
        throw error;
      }
      const data = await response.json();
      setLikelihoodResponse(data);
    } finally {
      setLoading(false);
    }
  };

  return { loading, postLikelihood };
};

export default usePostLikelihood;
