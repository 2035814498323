export const companySecurityOptions = [
  'Extremely Secure',
  'Moderately Secure',
  'Low Risk',
  'Zero Coverage'
];

export const riskToleranceOptions = [
  'High Risk Tolerance',
  'Medium Risk Tolerance',
  'Low Risk Tolernace'
];

export const securityPostureOptions = [
  'Highly Secure',
  'Moderately Secure',
  'Passively Secure'
];

export const complianceOptions = [
  'We are in a highly regulated industry',
  'We are in a moderately regulated industry',
  'Our industry is not regulated'
];

export const securityTeamOptions = [
  'We have a dedicated security team with a CISO',
  'We have a dedicated security team that reports into Information Technology',
  'We do not have a dedicated security team'
];

export const breachHistoryOptions = [
  'Yes, the company has experienced a breach',
  'No, the company has not experienced a breach'
];

export const incidentResponseOptions = [
  'Multiple times per year',
  'Once per year',
  'We do not practice Incident Response'
];
export const disasterRecoveryOptions = [
  'Multiple times per year',
  'Once per year',
  'We do not practice Disaster Recovery'
];
export const tabletopOptions = [
  'Multiple times per year',
  'Once per year',
  'We do not practice Tabletops/WarGames'
];
