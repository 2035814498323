import { useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthContext } from 'components/auth0/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const useUser = () => {
  const [user, setUser] = useState(null);
  const [isAddingMsp, setIsAddingMsp] = useState(false);
  const [goToMsp, setGoToMsp] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const authUser = useContext(AuthContext);
  const mspId = authUser?.msp?.id;
  const navigate = useNavigate();

  const fetchUser = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (!response.ok) {
        throw new Error('Error getting current user');
      }
      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const addUserMsp = async msp => {
    setIsAddingMsp(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/add-msp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(msp)
        }
      );
      if (!response.ok) {
        throw new Error('Error getting current user');
      }
      const data = await response.json();
      setUser(data);
      setGoToMsp(false); // Set goToMsp to false after adding MSP
      navigate('/');
    } finally {
      setIsAddingMsp(false);
    }
  };

  const addUser = async newUser => {
    try {
      const accessToken = await getAccessTokenSilently();
      const userWithMsp = { ...newUser, msp: { id: mspId } };

      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/create`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(userWithMsp)
        }
      );

      if (!response.ok) {
        throw new Error('Error adding new user');
      }

      const data = await response.json();
      setUser(data);
      toast.success(
        `User added successfully. An invitation email has been sent.`,
        {
          autoClose: 5000
        }
      );
      return data;
    } catch (error) {
      console.error('Error adding new user:', error);
      toast.error(`${error.message}`, {
        autoClose: 5000
      });
    }
  };

  const sendInvite = async user => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/${user.id}/invite/send`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Error sending invite');
      }
      // toast.success('Invite sent successfully', {
      //   autoClose: 5000,
      // });
    } catch (error) {
      console.error('Error sending invite:', error);
      toast.error(`${error.message}`, {
        autoClose: 5000
      });
    }
  };

  const reSendInvite = async user => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/${user.id}/invite/send`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Error sending invite');
      }
      toast.success('Invite re-sent successfully', {
        autoClose: 5000
      });
    } catch (error) {
      console.error('Error sending invite:', error);
      toast.error(`${error.message}`, {
        autoClose: 5000
      });
    }
  };

  const deleteUser = async userId => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/${userId}/delete`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        toast.error(`Error deleting user`, {
          autoClose: 5000
        });
        throw new Error('Error deleting user');
      }
      toast.success(`user deleted successfully`, {
        autoClose: 5000
      });
    } catch (error) {
      toast.error(`${error.message}`, {
        autoClose: 5000
      });
      console.error('Error deleting user:', error);
    }
  };

  return {
    user,
    fetchUser,
    isAddingMsp,
    addUserMsp,
    addUser,
    deleteUser,
    goToMsp,
    sendInvite,
    reSendInvite
  };
};

export default useUser;
