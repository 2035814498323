import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row, Form } from 'react-bootstrap';
import {
  incidentResponseOptions,
  disasterRecoveryOptions,
  tabletopOptions
} from 'data/clients/intakeWizardData';

const ReadinessForm = ({ register, errors, intake, setValue }) => {

  useEffect(() => {
    if (intake) {
      setValue('incidentResponse', intake.incidentResponse || '');
      setValue('disasterRecovery', intake.disasterRecovery || '');
      setValue('tabletop', intake.tabletop || '');
      setValue('disconnect', intake.disconnect || false);
      setValue('fear', intake.fear || false);
      setValue('hopeless', intake.hopeless || false);
      setValue('nonInclusion', intake.nonInclusion || false);
      setValue('businessFocus', intake.businessFocus || false);
    }
  }, [intake, setValue]);

  
  return (
    <>
      <h4>Incident Response Readiness</h4>
      <WizardInput
        type="select"
        label="How often do you practice Incident Response?"
        name="incidentResponse"
        errors={errors}
        options={incidentResponseOptions}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('incidentResponse')
        }}
      />
      <WizardInput
        type="select"
        label="Disater Recovery Readiness"
        name="disasterRecovery"
        errors={errors}
        options={disasterRecoveryOptions}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('disasterRecovery')
        }}
      />
      <WizardInput
        type="select"
        label="Tabletop/WarGame Readiness"
        name="tabletop"
        errors={errors}
        options={tabletopOptions}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('tabletop')
        }}
      />
      <Row className="g-4 my-3">
        <Form.Group className="mb-3">
          <Form.Label className="fs-8">
            Most organizations are experiencing one or more of the following.
            Please check all that apply:
          </Form.Label>
          <Col>
            <Row>
              <Col md={12}>
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="There is a disconnect between how the Security Team and Executive Team thinks about risk (Cyber is only one part of it)"
                  name="disconnect"
                  formControlProps={{
                    ...register('disconnect')
                  }}
                />
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="Security teams often needs to use fear to motivate executives to allocate more budget to meet their needs"
                  name="fear"
                  formControlProps={{
                    ...register('fear')
                  }}
                />
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="The executive team at times can feel a sense of hopelessness when it comes to cybersecurity and this can lead to inaction"
                  name="hopeless"
                  formControlProps={{
                    ...register('hopeless')
                  }}
                />
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="The cybersecurity team feels like they are always cut out of projects and are only brought in at the end resulting in budgets getting blown up and timelines being missed"
                  name="nonInclusion"
                  formControlProps={{
                    ...register('nonInclusion')
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="In general everyone wants to reduce risk, but when talking about how to make that happen cybersecurity teams focus on technology and leadership focuses on business systems"
                  name="businessFocus"
                  formControlProps={{
                    ...register('businessFocus')
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Form.Group>
      </Row>
    </>
  );
};

ReadinessForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default ReadinessForm;
