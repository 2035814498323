import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';

const SimStatItem = ({ stat }) => {
  return (
    <Col xs={6} md={3} className={`${stat.className} d-flex flex-column`}>
      <h6 className="pb-2 text-700">{stat.title}</h6>
      <>
        <p
          className="font-sans-serif lh-1 fs-tc mb-0 mt-auto"
          dangerouslySetInnerHTML={{ __html: stat.amount }}
        />
      </>
    </Col>
  );
};

SimStatItem.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string
  }),
  index: PropTypes.number,
  lastIndex: PropTypes.number
};

const SimStat = ({ data }) => {
  return (
    <Card className="py-3 mb-2">
      <Card.Body className="py-1">
        <Row className="g-0">
          {data.map((stat, index) => (
            <SimStatItem
              key={stat.title}
              stat={stat}
              index={index}
              lastIndex={data.length - 1}
            />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

SimStat.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SimStat;
