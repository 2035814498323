import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useMFA = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [error, setError] = useState(null);
  const [isCheckingMFA, setIsCheckingMFA] = useState(false);
  const [isEnrollingMFA, setIsEnrollingMFA] = useState(false);
  const [isRemovingMFA, setIsRemovingMFA] = useState(false);
  const isProcessing = isCheckingMFA || isEnrollingMFA || isRemovingMFA;

  const checkHasMFA = async () => {
    setIsCheckingMFA(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/mfa/enabled`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.ok) {
        const data = await response.json();
        setMfaEnabled(!!data);
      } else {
        setMfaEnabled(false);
      }
      setError(null);
    } catch (error) {
      console.error('Error in checkHasMFA:', error);
      setError('Error checking MFA status');
      setMfaEnabled(false);
    } finally {
      setIsCheckingMFA(false);
    }
  };

  const enrollMFA = async () => {
    setIsEnrollingMFA(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/mfa/enroll`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.ok) {
        const data = await response.json();
        setMfaEnabled(true);
        setError(null);
        return data;
      } else {
        const result = await response.text();
        setError(result);
        return null;
      }
    } catch (error) {
      console.error('Error in enrollMFA:', error);
      setError('Error enrolling in MFA');
    } finally {
      setIsEnrollingMFA(false);
    }
  };

  const removeMFA = async () => {
    setIsRemovingMFA(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/mfa/remove`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.ok) {
        setMfaEnabled(false);
        setError(null);
      } else {
        const result = await response.text();
        setError(result);
      }
    } catch (error) {
      console.error('Error in removeMFA:', error);
      setError('Error removing MFA');
    } finally {
      setIsRemovingMFA(false);
    }
  };

  return {
    checkHasMFA,
    enrollMFA,
    removeMFA,
    mfaEnabled,
    isProcessing,
    isCheckingMFA,
    isEnrollingMFA,
    isRemovingMFA,
    error
  };
};

export default useMFA;
