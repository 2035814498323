import React from 'react';
import PropTypes from 'prop-types';

const SecControlIcon = ({ icon, value, size = 36, imgSize = 24 }) => {
  const color = (function (value) {
    if (value < 50) return '#B82C46';
    else if (value === 50) return '#E3C734';
    return '#00894F';
  })(value);
  return (
    <div
      style={{
        backgroundColor: color,
        width: `${size}px`,
        height: `${size}px`,
        flex: `0 0 ${size}px`,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        alt=""
        src={icon}
        style={{
          maxWidth: `${imgSize}px`,
          height: `${imgSize}px`
        }}
      />
    </div>
  );
};

SecControlIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  size: PropTypes.number,
  imgSize: PropTypes.number
};

export default SecControlIcon;
