import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ClientWizardContext = createContext({ user: {} });

const ClientWizardProvider = ({ children }) => {
  const [client, setClient] = useState({});
  const [step, setStep] = useState(1);

  const value = { client, setClient, step, setStep };
  return (
    <ClientWizardContext.Provider value={value}>
      {children}
    </ClientWizardContext.Provider>
  );
};

ClientWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useClientWizardContext = () => useContext(ClientWizardContext);

export default ClientWizardProvider;
