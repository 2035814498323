import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import sailorShip from 'assets/img/icons/sailor-ship.png';
import formCompleted from 'assets/img/icons/spot-illustrations/checkmark-illustration.png';


const CompletedForm = () => {
  
  
  return (
    <>
    <div className='d-flex flex-column align-items-center justify-content-center'>
      <img
        src={formCompleted}
        alt="Image"
        className="img-fluid m-1 p-1"
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          verticalAlign: 'middle'
        }}
      />
      <h4 className='fw-bold text-center'>Hooray! You're all set!</h4>
      <p className='text-center'>Please check your email for a copy of the intake form to ensure your responses are accurate.</p>
    </div>
    </>
  );
};

export default CompletedForm;
