import React, { useEffect, useMemo } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowRight } from 'react-bootstrap-icons';
import * as yup from 'yup';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import {
  industries,
  breachTypes,
  countries
} from 'data/simulators/impactSimulatorData';
import { toast } from 'react-toastify';
import useGetClient from 'hooks/useGetClients';

const schema = yup
  .object({
    clientType: yup.string().required('This field is required.'),
    prospectName: yup.string().when('clientType', {
      is: 'prospect',
      then: schema => schema.required('This field is required.'),
      otherwise: schema => schema.notRequired()
    }),
    clientName: yup.object().when('clientType', {
      is: 'client',
      then: () =>
        yup.object({
          value: yup.string().required('This field is required.')
        }),
      otherwise: () => yup.object({ value: yup.string().notRequired() })
    }),
    industry: yup
      .object({
        value: yup.string().required('This field is required.')
      })
      .required('This field is required.'),
    breachType: yup
      .object({
        value: yup.string().required('This field is required.')
      })
      .required('This field is required.'),
    country: yup
      .object({
        value: yup.string().required('This field is required.')
      })
      .required('This field is required.'),
    worstRecords: yup
      .number()
      .required('This field is required.')
      .min(0, 'Value must be greater than 0.')
      .max(500000000, 'Value must be less than 500m.'),
    bestRecords: yup
      .number()
      .required('This field is required.')
      .min(0, 'Value must be greater than 0.')
      .max(500000000, 'Value must be less than 500m.')
      .max(
        yup.ref('worstRecords'),
        'Value must be less than Worst case records loss.'
      )
  })
  .required();

const ImpactSimulatorForm = ({ setFormData, hasResult, goToLikelihood }) => {
  const {
    getClients,
    isLoading: isLoadingClients,
    error: errorLadingClients,
    response: clients
  } = useGetClient();

  useEffect(() => {
    if (!clients) {
      getClients();
    }
  }, [clients]);

  useEffect(() => {
    if (errorLadingClients) {
      toast.error('Error loading clients');
    }
  }, [errorLadingClients]);

  const filteredClients = useMemo(
    () =>
      clients?.filter(
        client => client.prospect === false && client.deleted === false
      ) ?? [],
    [clients]
  );

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const isProspect = watch('clientType', 'client') === 'prospect';
  const clientName = watch('clientName');

  useEffect(() => {
    if (clientName) {
      const selectedClient = filteredClients.find(
        client => client.id === clientName.value
      );
      if (selectedClient) {
        setValue('industry', {
          value: selectedClient.industry,
          label: selectedClient.industry
        });
      }
    }
  }, [clientName, filteredClients, setValue]);

  const onSubmit = data => {
    setFormData({
      numberIterations: 1000000,
      minimumExposedRecords: parseInt(data.bestRecords),
      maximumExposedRecords: parseInt(data.worstRecords),
      isHardCostOnly: false,
      industryType: data.industry.value,
      country: data.country.value,
      breachType: data.breachType.value,
      mspClientId: !isProspect ? data.clientName.value : null,
      prospectName: isProspect ? data.prospectName : null,
      prospect: isProspect
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="g-3 mb-3">
        <Col xs={6} lg={2}>
          <Form.Check
            inline
            type="radio"
            label="Demo"
            name="clientType"
            {...register('clientType')}
            value="prospect"
            checked={isProspect}
          />
        </Col>
        <Col xs={6} lg={2}>
          <Form.Check
            type="radio"
            label="Existing Client"
            name="clientType"
            {...register('clientType')}
            value="client"
            checked={!isProspect}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={3}>
          {isProspect ? (
            <Form.Group className="mb-3">
              <Form.Label>Prospect Name</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.prospectName}
                {...register('prospectName')}
                placeholder="Prospect Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.prospectName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            // This is the part of the form that is validating
            //even when the clientType is 'prospect'.
            <Form.Group className="mb-3">
              <Form.Label>Client Name</Form.Label>
              {filteredClients ? (
                <Controller
                  control={control}
                  name="clientName"
                  render={({ field }) => (
                    <div>
                      <Select
                        classNamePrefix="react-select"
                        {...field}
                        options={filteredClients.map(client => ({
                          value: client.id,
                          label: client.name // Use client.name as the label
                        }))}
                        placeholder=""
                      />
                      {errors.clientName && (
                        <div className="invalid-feedback d-block">
                          {errors.clientName?.value?.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              ) : isLoadingClients ? (
                <Spinner animation="border" size="sm" role="Status">
                  <span className="visually-hidden">Loading clients...</span>
                </Spinner>
              ) : (
                <></>
              )}
            </Form.Group>
          )}
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Industry</Form.Label>
            <Controller
              control={control}
              name="industry"
              render={({ field }) => (
                <div>
                  <Select
                    classNamePrefix="react-select"
                    {...field}
                    options={industries.map(industry => ({
                      value: industry,
                      label: industry
                    }))}
                    placeholder=""
                  />
                  {errors.industry && (
                    <div className="invalid-feedback d-block">
                      {errors.industry?.value?.message}
                    </div>
                  )}
                </div>
              )}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Breach Type</Form.Label>
            <Controller
              control={control}
              name="breachType"
              render={({ field }) => (
                <div>
                  <Select
                    classNamePrefix="react-select"
                    {...field}
                    options={breachTypes.map(type => ({
                      value: type,
                      label: type
                    }))}
                    placeholder=""
                  />
                  {errors.breachType && (
                    <div className="invalid-feedback d-block">
                      {errors.breachType?.value?.message}
                    </div>
                  )}
                </div>
              )}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Country/Region</Form.Label>
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <div>
                  <Select
                    classNamePrefix="react-select"
                    {...field}
                    options={countries.map(country => ({
                      value: country,
                      label: country
                    }))}
                    placeholder=""
                  />
                  {errors.country && (
                    <div className="invalid-feedback d-block">
                      {errors.country?.value?.message}
                    </div>
                  )}
                </div>
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Best Case Record Loss</Form.Label>
            <Form.Control
              type="number"
              defaultValue={20000}
              isInvalid={!!errors.bestRecords}
              {...register('bestRecords')}
              placeholder="Best case records loss"
            />
            <Form.Control.Feedback type="invalid">
              {errors.bestRecords?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="mb-3">
            <Form.Label>Worst Case Record Loss</Form.Label>
            <Form.Control
              type="number"
              defaultValue={50000}
              isInvalid={!!errors.worstRecords}
              {...register('worstRecords')}
              placeholder="Worst case records loss"
            />
            <Form.Control.Feedback type="invalid">
              {errors.worstRecords?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Label>&nbsp;</Form.Label>
          <Button
            type="submit"
            variant="primary"
            className="w-100"
            disabled={!isValid}
          >
            Run Simulation
          </Button>
        </Col>
        <Col lg={3}>
          <Form.Label>&nbsp;</Form.Label>
          <Button
            type="button"
            variant="warning"
            className="w-100"
            disabled={!hasResult}
            onClick={goToLikelihood}
          >
            Proceed to Likelihood <ArrowRight />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
ImpactSimulatorForm.propTypes = {
  setFormData: PropTypes.func.isRequired,
  hasResult: PropTypes.bool.isRequired,
  goToLikelihood: PropTypes.func.isRequired
};
export default ImpactSimulatorForm;
