import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import download from 'downloadjs';

const useReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const downloadReport = async simulationId => {
    setIsLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/simulations/${simulationId}/report`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        // Parse the error message
        const errorData = await response.text(); // or .json() depending on response format
        const errorMessage = `An error occurred: ${errorData}`;
        throw new Error(errorMessage);
      }

      const blob = await response.blob();
      download(blob, `report-${simulationId}.pdf`, 'application/pdf');
    } finally {
      setIsLoading(false);
    }
  };

  return { downloadReport, isLoading };
};
export default useReport;
