import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import React, { useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import './FeedbackForm.css';

const RadioScore = ({ name, value, currentScore, setScore }) => {
  return (
    <label className="col-sm-1 radio-score">
      <input
        type="radio"
        name={name}
        value={value}
        checked={currentScore === value}
        onChange={e => setScore(e.target.value)}
        required
      />{' '}
      {value}
    </label>
  );
};

RadioScore.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setScore: PropTypes.func.isRequired,
  currentScore: PropTypes.string
};

const FeedbackForm = () => {
  const [satisfactionScore, setSatisfactionScore] = useState(null);
  const [navigationScore, setNavigationScore] = useState(null);
  const [taskCompleted, setTaskCompleted] = useState(null);
  const [taskCompletedExplanation, setTaskCompletedExplanation] = useState('');
  const [positiveFeedback, setPositiveFeedback] = useState('');
  const [negativeFeedback, setNegativeFeedback] = useState('');
  const [improvements, setImprovements] = useState('');
  const [missingFeatures, setMissingFeatures] = useState('');
  const [otherFeedback, setOtherFeedback] = useState('');
  const [message, setMessage] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      const accessToken = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/feedback/send`,
        {
          satisfactionScore,
          navigationScore,
          taskCompleted,
          taskCompletedExplanation,
          positiveFeedback,
          negativeFeedback,
          improvements,
          missingFeatures,
          otherFeedback
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setMessage('Feedback sent successfully!');
      clearForm();
    } catch (error) {
      console.error('Error sending feedback:', error);
      setMessage('Error sending feedback.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const clearForm = () => {
    setSatisfactionScore(null);
    setNavigationScore(null);
    setTaskCompleted(null);
    setTaskCompletedExplanation('');
    setPositiveFeedback('');
    setNegativeFeedback('');
    setImprovements('');
    setMissingFeatures('');
    setOtherFeedback('');
  };

  return (
    <Container className="mt-3">
      <Card className="feedback-form">
        <Card.Header style={{ background: '#D8E2EF' }}>
          <h4 className="m-0">Feedback Form</h4>
        </Card.Header>
        <Card.Body>
          <form onSubmit={handleSubmit}>
            <ol className="mb-3">
              <li>
                <h5>Overall Satisfaction</h5>
                <p>
                  How satisfied are you with your overall experience using the
                  ThreatCaptain Platform?
                </p>
                {Array(10)
                  .fill()
                  .map((_, score) => {
                    return (
                      <RadioScore
                        key={'satisfactionScore' + score}
                        name="satisfactionScore"
                        value={(score + 1).toString()}
                        setScore={setSatisfactionScore}
                        currentScore={satisfactionScore}
                      />
                    );
                  })}
              </li>
              <li className="ease-navigation">
                <h5>Ease of Navigation</h5>
                <p>How easy was it to navigate through the platform?</p>
                <label>
                  <input
                    type="radio"
                    name="navigationScore"
                    value="1"
                    checked={navigationScore === '1'}
                    onChange={e => setNavigationScore(e.target.value)}
                    required
                  />
                  Very Difficult
                </label>
                <label>
                  <input
                    type="radio"
                    name="navigationScore"
                    value="2"
                    checked={navigationScore === '2'}
                    onChange={e => setNavigationScore(e.target.value)}
                    required
                  />
                  Difficult
                </label>
                <label>
                  <input
                    type="radio"
                    name="navigationScore"
                    value="3"
                    checked={navigationScore === '3'}
                    onChange={e => setNavigationScore(e.target.value)}
                    required
                  />
                  Neutral
                </label>
                <label>
                  <input
                    type="radio"
                    name="navigationScore"
                    value="4"
                    checked={navigationScore === '4'}
                    onChange={e => setNavigationScore(e.target.value)}
                    required
                  />
                  Easy
                </label>
                <label>
                  <input
                    type="radio"
                    name="navigationScore"
                    value="5"
                    checked={navigationScore === '5'}
                    onChange={e => setNavigationScore(e.target.value)}
                    required
                  />
                  Very Easy
                </label>
              </li>
              <li className="task-completion">
                <h5>Task Completion</h5>
                <p>
                  Were you able to complete your intended tasks without any
                  issues?
                </p>
                <label>
                  <input
                    type="radio"
                    name="taskCompleted"
                    value="true"
                    checked={taskCompleted === 'true'}
                    onChange={() => setTaskCompleted('true')}
                    required
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="taskCompleted"
                    value="false"
                    checked={taskCompleted === 'false'}
                    onChange={() => setTaskCompleted('false')}
                    required
                  />
                  No
                </label>
                {taskCompleted === 'false' && (
                  <textarea
                    name="taskCompletedExplanation"
                    placeholder="If no, please explain"
                    value={taskCompletedExplanation}
                    onChange={e => setTaskCompletedExplanation(e.target.value)}
                  />
                )}
              </li>
              <li className="positive">
                <h5>Positive Aspects</h5>
                <p>What did you like most about the ThreatCaptain platform?</p>
                <textarea
                  name="positiveFeedback"
                  value={positiveFeedback}
                  onChange={e => setPositiveFeedback(e.target.value)}
                />
              </li>
              <li className="negative">
                <h5>Negative Aspects</h5>
                <p>What did you like least about the ThreatCaptain platform?</p>
                <textarea
                  name="negativeFeedback"
                  value={negativeFeedback}
                  onChange={e => setNegativeFeedback(e.target.value)}
                />
              </li>
              <li className="improvements">
                <h5>Feature Improvement</h5>
                <p>Is there any feature you would like to see improved?</p>
                <textarea
                  name="improvements"
                  value={improvements}
                  onChange={e => setImprovements(e.target.value)}
                />
              </li>
              <li className="missing">
                <h5>Missing Features</h5>
                <p>
                  Are there any features you feel are missing from the platform?
                </p>
                <textarea
                  name="missingFeatures"
                  value={missingFeatures}
                  onChange={e => setMissingFeatures(e.target.value)}
                />
              </li>
              <li className="other">
                <h5>Additional Comments</h5>
                <p>
                  Any other comments or suggestions you would like to share with
                  us?
                </p>
                <textarea
                  name="otherFeedback"
                  value={otherFeedback}
                  onChange={e => setOtherFeedback(e.target.value)}
                />
              </li>
            </ol>
            <div className="text-center my-2">
              <Button
                type="submit"
                className="btn-sm"
                style={{ backgroundColor: '#00D27A', borderColor: '#00D27A' }}
                onMouseOver={e => {
                  e.currentTarget.style.boxShadow =
                    '0 0 10px rgba(0, 0, 0, 0.2)';
                }}
                onMouseOut={e => {
                  e.currentTarget.style.boxShadow = 'none';
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
              </Button>
            </div>
            {message && <p className="text-center">{message}</p>}
          </form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FeedbackForm;
