import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import GeneralForm from './GeneralForm';
import PrimaryContactForm from './PrimaryContactForm';
import { useClientWizardContext } from 'providers/ClientWizardProvider';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { useNavigate } from 'react-router-dom';
import useSaveClient from 'hooks/useSaveClient';
import useSaveContact from 'hooks/useSaveContact';

const WizardLayout = ({ variant, validation }) => {
  const { isRTL } = useAppContext();
  const { client, setClient, step, setStep } = useClientWizardContext();
  const navigate = useNavigate();
  const { saveClient } = useSaveClient();
  const { saveContact } = useSaveContact();
  const [validationError, setValidationError] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError
  } = useForm();

  const navItems = [
    {
      icon: 'user',
      label: 'General Information'
    },
    {
      icon: 'star',
      label: 'Primary Contact'
    }
  ];

  const onSubmitData = async data => {
    if (step === 1) {
      setClient({ ...client, ...data });
      //saveClient(data);
      setStep(step + 1);
    }
    if (step === 2) {
      const updatedClient = { ...client, ...data };

      const { status, error } = await saveClient(updatedClient);

      if (status === 200) {
        setValidationError(''); // Clear previous validation errors

        // Perform additional actions based on data
        await saveContact(data, localStorage.getItem('clientId'));

        if (data.prospect) {
          navigate('/clients');
        } else {
          // TODO: Back to regular link once intake form is fixed
          navigate(`/clients/${localStorage.getItem('clientId')}/intake`);
          //navigate('/clients');
        }
      } else {
        // Handle other errors
        if (typeof error === 'object' && error.error) {
          handleError(error);
        } else {
          setValidationError(error); // Display the error message
        }
        console.error('API Error:', error);
      }
    }
  };

  const handleError = error => {
    if (error.error === 'DuplicateClient') {
      if (error.sameName) {
        setError('name', {
          type: 'custom',
          message: 'Client with same name already exist'
        });
      }
      if (error.sameEmail) {
        setError('email', {
          type: 'custom',
          message: 'Client with same e-mail already exist'
        });
      }
      if (error.samePhone) {
        setError('phone', {
          type: 'custom',
          message: 'Client with same phone already exist'
        });
      }
      setStep(1);
    } else {
      setValidationError(error.message);
    }
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const handleNavs = targetStep => {
    if (step !== 2) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    }
  };

  return (
    <>
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5 p-0"
      >
        <Card.Header
          className={classNames('bg-body-tertiary', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <GeneralForm
              register={register}
              errors={errors}
              setValue={setValue}
            />
          )}
          {step === 2 && (
            <PrimaryContactForm
              register={register}
              errors={errors}
              setValue={setValue}
            />
          )}
          {validationError && <p className="text-danger">{validationError}</p>}
        </Card.Body>
        <Card.Footer className="px-md-6 bg-body-tertiary hstack gap-3">
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 p-2 fw-semibold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>
          {step === 2 && client.prospect ? (
            <Button
              variant="primary"
              className="p-2 px-5 ms-auto"
              type="submit"
              transform="down-1 shrink-4"
            >
              Save & Close
            </Button>
          ) : (
            <IconButton
              variant="primary"
              className="p-2 px-5 ms-auto"
              type="submit"
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
            >
              Save & Next
            </IconButton>
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: index < 2 ? step > index : step > 2,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
