import React from 'react';
import { Row } from 'react-bootstrap';
import IntakeHeader from './IntakeHeader';
import Wizard from './ClientIntakeWizard/Wizard';

const ClientIntake = () => {
  return (
    <>
      <Row className="g-3">
        <IntakeHeader />
      </Row>
      <Row className="g-3">
        <Wizard validation={true} />
      </Row>
    </>
  );
};

export default ClientIntake;
