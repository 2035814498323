// src/accountSettings/SecuritySettings.js
import React, { useState, useRef, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { rootPaths } from 'routes/paths';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import useChangePass from 'hooks/useChangePass';
import useMFA from 'hooks/useMFA';
import 'react-toastify/dist/ReactToastify.css';

const SecuritySettings = ({ userInfo }) => {
  const { changePass, isSubmitting } = useChangePass();
  const {
    checkHasMFA,
    enrollMFA,
    removeMFA,
    mfaEnabled,
    isProcessing: isProcessingMFA,
    error: errorMFA
  } = useMFA();
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const canChangePassword = userInfo?.auth0Id?.split('|')[0] === 'auth0';
  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);

  useEffect(() => {
    checkHasMFA();
  }, []);

  const handleMFAChange = async () => {
    if (mfaEnabled) {
      // We have to confirm first
      if (window.confirm('Are you sure you want to disable MFA?')) {
        await removeMFA();
      } else {
        return;
      }
    } else {
      const enrollTicket = await enrollMFA();
      if (enrollTicket && enrollTicket.ticket_url) {
        const newWindow = window.open(enrollTicket.ticket_url, '_blank');
        const interval = setInterval(() => {
          if (newWindow.closed) {
            clearInterval(interval);
            checkHasMFA();
          }
        }, 1000); // Check every second
      }
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      toast.error('Passwords do not match');
      // Focus the password field
      newPasswordRef.current.focus();
      return;
    }
    const response = await changePass({
      currentPassword: formData.currentPassword,
      newPassword: formData.newPassword
    });
    if (response.ok) {
      toast.success('Password updated successfully');
    } else {
      if (response.status === 401) {
        toast.error('Current password is incorrect');
        // Focus the password field
        currentPasswordRef.current.focus();
      } else {
        toast.error('Failed to update password');
      }
    }
  };

  return (
    <div className="security-settings">
      <ToastContainer />
      <h6>Username</h6>
      <p>{userInfo?.email ?? ''}</p>
      <Form onSubmit={handleSubmit} className="security-settings-card-body">
        <Form.Switch
          id="enableMFA"
          label="Use Multi-Factor Authentication"
          inline
          checked={mfaEnabled}
          onChange={handleMFAChange}
          style={{ marginTop: '1rem' }}
          disabled={isProcessingMFA}
          feedback={errorMFA}
          feedbackType={errorMFA !== null ? 'invalid' : 'valid'}
          isInvalid={errorMFA !== null}
        />

        {isProcessingMFA && (
          <Spinner animation="border" size="sm" role="Status">
            <span className="visually-hidden">Verifying MFA status...</span>
          </Spinner>
        )}
        {canChangePassword && (
          <>
            <h6>Change password</h6>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formCurrentPassword">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    ref={currentPasswordRef}
                    type="password"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formNewPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    ref={newPasswordRef}
                    type="password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formConfirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4 justify-content align-items-center">
              <Col xs="auto">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Please wait...' : 'Save Changes'}
                </Button>
              </Col>
              <Col xs="auto">
                <Link
                  className="cancel"
                  to={rootPaths.root}
                  style={{ color: 'black' }}
                >
                  Cancel
                </Link>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
};

SecuritySettings.propTypes = {
  userInfo: PropTypes.object
};

export default SecuritySettings;
