import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from 'react-bootstrap/Spinner';
import paths from 'routes/paths';

const BillingReturn = () => {
  const [status, setStatus] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const cancelled = urlParams.get('cancelled');

    if (cancelled) {
      setStatus('open');
    } else {
      getAccessTokenSilently().then(accessToken => {
        fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/subscription/checkout-status?session_id=${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
          .then(res => res.json())
          .then(data => {
            setStatus(data.status);
            if (data.status === 'complete') {
              navigate(paths.landing);
            }
          });
      });
    }
  }, []);

  if (status === 'open') {
    return <Navigate to={paths.billing} />;
  }

  if (status === 'complete') {
    return <Navigate to="/" />;
  }

  return (
    <Spinner animation="border" size="xl" role="Status">
      <span className="visually-hidden">Verifying subscription...</span>
    </Spinner>
  );
};
export default BillingReturn;
