import React from 'react';
import { Row } from 'react-bootstrap';
import AddClientHeader from './IntakeHeader';
import Wizard from './updateClientWizard/Wizard';

const UpdateClient = () => {
  return (
    <>
      <Row className="g-3">
        <AddClientHeader />
      </Row>
      <Row className="g-3">
        <Wizard validation={true} />
      </Row>
    </>
  );
};

export default UpdateClient;
