import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

// Create the context
export const AccountSettingsContext = createContext({ user: {} });

// Create the provider component
export const AccountSettingsProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});

  const value = { userInfo, setUserInfo };

  return (
    <AccountSettingsContext.Provider value={value}>
      {children}
    </AccountSettingsContext.Provider>
  );
};

AccountSettingsProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const useAccountSettingsContext = () => useContext(AccountSettingsContext);

export default AccountSettingsProvider;
