import { useAuth0 } from '@auth0/auth0-react';
import Priority from 'components/clientsDashboard/client-list/Priority';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';
import useAccount from 'hooks/useAccount';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PropTypes from 'prop-types';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useEffect, useState } from 'react';
import { Card, Dropdown, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import ConfirmDeletion from '../../common/ConfirmDeletion';
import { useFilter } from '../ClientListContext';
import ClientListHeader from './ClientListHeader';
import 
{ 
  faBook,faCoins,faBolt,faHeartbeat,faBookMedical,faIndustry,
  faMicrochip,faTools,faFilm,faBus,faComments,faShoppingCart,
  faSchool,faBuilding,faStore,faMicroscope, faHome


} 
  from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const industryIconMap = {
  Education: faBook,
  Financial: faCoins,
  Healthcare: faHeartbeat,
  Energy: faBolt,
  Pharmaceuticals: faBookMedical,
  Industrial: faIndustry,
  Technology: faMicrochip,
  Services: faTools,
  Entertainment: faFilm,
  Transportation: faBus,
  Communication: faComments,
  Consumer: faShoppingCart,
  Media: faSchool,
  Hospitality: faBuilding,
  Retail: faStore,
  Research: faMicroscope,
  Public: faHome,
  None: faHome,
};

/**
 * the accessorKey must match the key in the data object for sorting!
 */

const columns = [
  {
    accessorKey: 'name',
    header: 'Client Accounts',
    meta: {
      headerProps: { className: 'pe-1 sticky-column text-900' },
      cellProps: {
        className: 'fw-semibold'
      }
    },
    cell: ({ row: { original } }) => {
      const { name } = original;
      return <Link to={paths.clientDetails}>{name}</Link>;
    }
  },
  {
    accessorKey: 'primaryName',
    header: 'Primary Contact',
    meta: {
      headerProps: {
        style: { minWidth: '7rem' },
        className: 'text-900'
      },
      cellProps: {
        className: 'py-2'
      }
    },
    cell: ({ row: { original } }) => {}
  },
  {
    accessorKey: 'status',
    header: 'Status',
    meta: {
      headerProps: { className: 'text-900' },
      cellProps: { className: 'fs-9' }
    },
    cell: ({ row: { original } }) => {}
  },
  {
    accessorKey: 'tolerance',
    header: 'Risk Tolerance',
    meta: {
      headerProps: { className: 'text-900' },
      cellProps: {
        className: 'pe-2'
      }
    },
    cell: ({ row: { original } }) => {
      const { tolerancePriority } = original;
      return (
        <Priority
          title={tolerancePriority.title}
          color={tolerancePriority.color}
          data={tolerancePriority.data}
        />
      );
    }
  },
  {
    accessorKey: 'posture',
    header: 'Security Posture',
    meta: {
      headerProps: { className: 'text-900' },
      cellProps: { className: 'fs-9' }
    },
    cell: ({ row: { original } }) => {
      const { postureBadge } = original;
      return (
        <SubtleBadge pill bg={postureBadge.type}>
          {postureBadge.content}
        </SubtleBadge>
      );
    }
  },
  {
    accessorKey: 'likelihood',
    header: 'Likelihood',
    meta: {
      headerProps: { className: 'text-900' },
      cellProps: { className: 'fs-9' }
    },
    cell: ({ row: { original } }) => {
      const { likelihood } = original;
      return (
        <Flex alignItems="center">
          <p className="mb-0 me-2">{likelihood.value}%</p>
          <ProgressBar
            variant={likelihood.color}
            now={likelihood.value}
            style={{ height: '0.3125rem', width: '3.8rem' }}
          />
        </Flex>
      );
    }
  },
  {
    accessorKey: 'industry',
    header: 'Industry',
    meta: {
      headerProps: {
        className: 'text-900'
      }
    },
    cell: ({ row: { original } }) => {
      const { industry } = original;
      return (
        <Flex alignItems="center" className="position-relative py-1">
          <h6 className="mb-0 text-900">{industry}</h6>
        </Flex>
      );
    }
  },
  {
    accessorKey: 'lastActivity',
    header: 'Last Activity',
    meta: {
      headerProps: {
        className: ' text-900'
      }
    },
    cell: ({ row: { original } }) => {
      const { lastActivity } = original;
      return (
        <Flex alignItems="center" className="position-relative py-1">
          <h6 className="mb-0 text-900">{lastActivity}</h6>
        </Flex>
      );
    }
  },
  {
    accessorKey: 'none',
    header: '',
    enableSorting: false,
    meta: {
      cellProps: {
        className: 'text-end py-2'
      }
    },
    cell: () => {
      return (
        <CardDropdown drop="start">
          <div className="py-2">
            <Dropdown.Item href="#!">View</Dropdown.Item>
            <Dropdown.Item href="#!">Edit</Dropdown.Item>
            <Dropdown.Divider as="div" />
            <Dropdown.Item href="#!" className="text-danger">
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const RecentPurchases = ({ clientData }) => {};

export const ClientList = ({ data }) => {
  const [userInfo, setUserInfo] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const { returnAccount } = useAccount();
  const [tableData, setTableData] = useState(data);
  const { filters } = useFilter();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);

  useEffect(() => {
    const loadUserInfo = async () => {
      const thisUser = await returnAccount();
      setUserInfo(thisUser);
    };

    loadUserInfo();
  }, [userInfo]);

  const columnsForClients = [
    {
      accessorKey: 'name',
      header: 'Accounts',
      meta: {
        headerProps: { className: 'pe-1 sticky-column text-900' },
        cellProps: {
          className: 'fw-semibold'
        }
      },
      cell: ({ row: { original } }) => {
        const { name, id } = original;
        return <Link to={`${paths.clients}/${id}/details`}>{name}</Link>;
      }
    },
    {
      accessorKey: 'primaryName',
      header: 'Primary Contact',
      meta: {
        headerProps: {
          style: { minWidth: '7rem' },
          className: 'text-900'
        },
        cellProps: {
          className: 'py-2'
        }
      },
      cell: ({ row: { original } }) => {
        const { contacts } = original;
        return (
          <div>
            <h6>{contacts[0]?.name ?? 'No contact information'}</h6>
            <div>
              {contacts[0]?.contactMethod === 'email' ? (
                <a href={`mailto:${contacts[0]?.email}`}>
                  {contacts[0]?.email}
                </a>
              ) : (
                <a href={`tel:${contacts[0]?.phone}`}>{contacts[0]?.phone}</a>
              )}
            </div>
          </div>
        );
      }
    },
    {
      accessorKey: 'status',
      header: 'Type',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: { className: 'fs-9' }
      },
      cell: ({ row: { original } }) => {
        const { prospect } = original;
        return (
          <SubtleBadge pill bg={prospect ? 'primary' : 'success'}>
            {prospect ? 'Prospect' : 'Client'}
          </SubtleBadge>
        );
      }
    },
    {
      accessorKey: 'website',
      header: 'Website',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: {
          className: 'pe-2'
        }
      },
      cell: ({ row: { original } }) => {
        const { website } = original;
        return (
          <div>
            <div>{website}</div>
          </div>
        );
      }
    },
    {
      accessorKey: 'industry',
      header: 'Industry',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: {
          className: 'pe-2'
        }
      },
      cell: ({ row: { original } }) => {
        const { industry } = original;
        const icon = industryIconMap[industry];
    
        return (
          <Flex>
          {icon && (
            <FontAwesomeIcon style={{ marginRight: '8px' }} icon={icon} />
          )}
          <h6 className="mb-0 text-900">{industry}</h6>
        </Flex>
        );
      }
    },
    {
      accessorKey: 'securityScore',
      header: 'Security Score',
      meta: {
        headerProps: { className: 'text-900' },
        cellProps: {
          className: 'pe-2'
        }
      },
      cell: ({ row: { original } }) => {
        const { securityScore } = original;
        let badgeProps = { pill: true, bg: 'secondary', text: 'Unknown' };
    
        if (securityScore >= 500 && securityScore <= 600) {
          badgeProps = { pill: true, bg: 'success', text: 'Secured' };
        } else if (securityScore >= 400 && securityScore < 500) {
          badgeProps = { pill: true, bg: 'dim', text: 'Ok' };
        } else if (securityScore >= 200 && securityScore < 400) {
          badgeProps = { pill: true, bg: 'warning', text: 'At Risk' };
        } else if (securityScore >= 0 && securityScore < 200) {
          badgeProps = { pill: true, bg: 'danger', text: 'Critical' };
        }
    
        return (
          <div>
            <SubtleBadge pill bg={badgeProps.bg}>
              {badgeProps.text}
            </SubtleBadge>
          </div>
        );
      }
    },
    {
      accessorKey: 'modificationDate',
      header: 'Last Activity',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: ({ row: { original } }) => {
        const { modificationDate } = original;

        const date = new Date(modificationDate);
        const formattedDate = date.toLocaleDateString();
        const formattedTime = date.toLocaleTimeString();

        return (
          <Flex alignItems="center" className="position-relative py-1">
            <h6 className="mb-0 text-900">{`${formattedDate} ${formattedTime}`}</h6>
          </Flex>
        );
      }
    },
    {
      accessorKey: 'none',
      header: '',
      enableSorting: false,
      meta: {
        cellProps: {
          className: 'text-end py-2'
        }
      },
      cell: ({ row: { original } }) => {
        const { id } = original;
        return (
          <CardDropdown drop="start">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => navigate(`${paths.clients}/${id}/details`)}
              >
                View
              </Dropdown.Item>
              {userInfo?.isAdmin && (
                <>
                  <Dropdown.Item
                    onClick={() => navigate(`/clients/update/${id}`)}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Divider as="div" />
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => handleDeleteClick(id)}
                  >
                    Delete
                  </Dropdown.Item>
                </>
              )}
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const handleDeleteClick = clientId => {
    setSelectedClientId(clientId);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    //setShowModal(false);
    try {
      const accessToken = await getAccessTokenSilently();
      await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/${selectedClientId}/delete`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      setTableData(tableData.filter(client => client.id !== selectedClientId));
    } catch (error) {
      console.error('Error deleting client:', error);
    } finally {
      setShowModal(false);
      setSelectedClientId(null);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (data) {
      const now = new Date();

      // Apply filtering based on industry and prospect
      const filteredData = data.filter(item => {
        const industryMatch =
          filters.industry === 'All' || item.industry === filters.industry;
        const prospectMatch =
          filters.prospect === undefined || item.prospect === filters.prospect;

        const creationDate = new Date(item.creationDate);
        const timingMatch =
          filters.timing === 'All' ||
          filters.timing === undefined ||
          (() => {
            const days = parseInt(filters.timing, 10);
            const cutoffDate = new Date(
              now.getTime() - days * 24 * 60 * 60 * 1000
            );
            return creationDate >= cutoffDate;
          })();

        return industryMatch && prospectMatch && timingMatch;
      });
      setTableData(filteredData);
    }
  }, [data, filters, navigate]); // Update when filters or props.data changes

  const table = useAdvanceTable({
    data: tableData,
    columns: columnsForClients,
    selection: true,
    sortable: true,
    pagination: true,
    perPage: 10
  });

  return (
    <>
      <AdvanceTableProvider {...table}>
        <Card>
          <Card.Header>
            <ClientListHeader
              rowCount={tableData.length}
              clientData={tableData}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                size: 'sm',
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination />
          </Card.Footer>
        </Card>
      </AdvanceTableProvider>

      <ConfirmDeletion
        show={showModal}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        message={'Are you sure you want to delete this account?'}
      />
    </>
  );
};

ClientList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      industry: PropTypes.string.isRequired,
      website: PropTypes.string,
      phone: PropTypes.string,
      timezone: PropTypes.string,
      address: PropTypes.string,
      joinDate: PropTypes.string,
      creationDate: PropTypes.string.isRequired,
      modificationDate: PropTypes.string.isRequired,
      deleted: PropTypes.bool.isRequired,
      contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
      prospect: PropTypes.bool.isRequired
    })
  ).isRequired
};
