import PropTypes from 'prop-types';
import React from 'react';

const LikelihoodRange = ({
  name,
  min = 0,
  partial = 50,
  max = 100,
  value,
  onChange,
  onBlur
}) => {
  if (value === undefined) {
    value = min;
  }

  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={name}
          id={`${name}1`}
          value={max}
          onBlur={onBlur}
          onChange={() => onChange(max)}
          checked={value === max}
        />
        <label className="form-check-label" htmlFor={`${name}1`}>
          Fully Deployed
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={name}
          id={`${name}2`}
          value={partial}
          onBlur={onBlur}
          onChange={() => onChange(partial)}
          checked={value === partial}
        />
        <label className="form-check-label" htmlFor={`${name}2`}>
          Partially Deployed
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name={name}
          id={`${name}3`}
          value={min}
          onBlur={onBlur}
          onChange={() => onChange(min)}
          checked={value === min}
        />
        <label className="form-check-label" htmlFor={`${name}3`}>
          Not Deployed
        </label>
      </div>
    </>
  );
};

LikelihoodRange.propTypes = {
  name: PropTypes.string.isRequired,
  min: PropTypes.number,
  partial: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
};

export default LikelihoodRange;
