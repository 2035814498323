import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const ControlsRadioInput = ({
  label,
  name,
  value,
  options = [],
  errors,
  formControlProps,
  formGroupProps,
  setValue,
  required = false,
  setColor,
  setColorValue
}) => {

    const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    const newColorValue = setColorValue(newValue);
    setValue(name, newValue);
    setSelectedValue(newValue);
    
    setColor(newColorValue);
  };

  return (
    <Form.Group {...formGroupProps}>
      {options.map((option) => (
        <Form.Check
          key={option.value}
          type="radio"
          id={`${name}-${option.value}`}
          name={name}
          label={option.label}
          value={option.value}
          checked={selectedValue === option.value}
          onChange={handleChange}
          isInvalid={errors[name]}
          required={required}
        />
      ))}
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

ControlsRadioInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  errors: PropTypes.object,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  required: PropTypes.bool,
  setColor: PropTypes.func.isRequired,
  setColorValue: PropTypes.func.isRequired
};

export default ControlsRadioInput;
