import { GenericError, useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const CallbackPage = () => {
  const { error } = useAuth0();

  if (error) {
    return (
      <GenericError
        title="An error occurred while attempting to log in."
        code={error.code}
      >
        {error.error_description}
      </GenericError>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
      }}
    >
      <Spinner animation="border" size="xl" role="Status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default CallbackPage;
