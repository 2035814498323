import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import BusinessForm from './BusinessForm';
import SecurityForm from './SecurityForm';
import ControlsForm from './ControlsForm';
import PoliciesForm from './PoliciesForm';
import ReadinessForm from './ReadinessForm';
import CompletedForm from './CompletedForm';
import { useIntakeWizardContext } from 'providers/IntakeWizardProvider';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { useNavigate } from 'react-router-dom';
import useSaveIntake from 'hooks/useSaveIntake';
import useIntake from 'hooks/useIntake';
import paths from 'routes/paths';
import { faRecycle, faCheck } from '@fortawesome/free-solid-svg-icons';

const WizardLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useAppContext();
  const navigate = useNavigate();
  const { intake, setIntake, step, setStep } = useIntakeWizardContext();
  const { saveIntake } = useSaveIntake();
  const { fetchIntake } = useIntake();

  const [saveAndClose, setSaveAndClose] = useState(false);

  useEffect(() => {
    const loadIntakeForm = async () => {
      await fetchIntake(setIntake);
    };

    loadIntakeForm();
  }, []);

  const resetIntake = () => {
    navigate(paths.addClient);
  };

  const intakeDone = () => {
    navigate(paths.clients);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control
  } = useForm();

  const navItems = [
    {
      icon: 'building',
      label: 'Business'
    },
    {
      icon: 'lock',
      label: 'Security'
    },
    {
      icon: 'key',
      label: 'Controls'
    },
    {
      icon: 'shield-alt',
      label: 'Policies'
    },
    {
      icon: 'thumbs-up',
      label: 'Readiness '
    }
  ];

  const onSubmitData = data => {
    if (saveAndClose === true) {
      data.completed = true;
      saveIntake({
        ...intake,
        ...data
      });
      setSaveAndClose(false);
      navigate(paths.clients);
    }

    if (step === 5) {
      data.completed = true;
      setIntake(prevIntake => ({
        ...prevIntake,
        ...data
      }));
      saveIntake(data);
      setStep(step + 1);
      //TODO: Send email here
    } else {
      data.started = true;
      setIntake(prevIntake => ({
        ...prevIntake,
        ...data
      }));
      //saveIntake(data);
      setStep(step + 1);
    }
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const handleNavs = targetStep => {
    if (step !== 5) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    }
  };

  return (
    <>
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5 p-0"
      >
        <Card.Header
          className={classNames('bg-body-tertiary', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
            {step === 6 && <NavItemCompletion step={step} />}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 20} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <BusinessForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
          {step === 2 && (
            <SecurityForm
              register={register}
              errors={errors}
              setValue={setValue}
              control={control}
              intake={intake}
            />
          )}
          {step === 3 && (
            <ControlsForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
          {step === 4 && (
            <PoliciesForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
          {step === 5 && (
            <ReadinessForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
          {step === 6 && (
            <CompletedForm
              register={register}
              errors={errors}
              setValue={setValue}
              intake={intake}
            />
          )}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-body-tertiary hstack gap-3', {
            'd-none': step === 7,
            ' d-flex': step < 7
          })}
        >
          <div className="row w-100">
            <div className="col-6 d-flex align-items-center">
              {step === 6 ? (
                <></>
              ) : step !== 5 ? (
                <IconButton
                  variant="link"
                  icon={isRTL ? 'chevron-right' : 'chevron-left'}
                  iconAlign="left"
                  transform="down-1 shrink-4"
                  className={classNames('px-0 p-2 fw-semibold', {
                    'd-none': step === 1
                  })}
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </IconButton>
              ) : (
                <p className="fw-bold"> Nice Job! You're almost done</p>
              )}
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              {step === 6 ? (
                <>
                  <Button
                    variant="success"
                    className="ps-2 pr-2 m-1"
                    type="submit"
                    transform="down-1 shrink-4"
                    onClick={() => resetIntake()}
                  >
                    <FontAwesomeIcon className="me-2 ms-2" icon={faRecycle} />
                    Reset Form
                  </Button>
                  <Button
                    variant="secondary"
                    className="ps-2 pr-2 m-1"
                    type="button"
                    transform="down-1 shrink-4"
                    onClick={() => intakeDone()}
                  >
                    <FontAwesomeIcon className="me-2 ms-2" icon={faCheck} />
                    Done
                  </Button>
                </>
              ) : step !== 5 ? (
                <Button
                  variant="falcon-primary"
                  className="ps-2 pr-2 m-1"
                  type="submit"
                  transform="down-1 shrink-4"
                  onClick={() => setSaveAndClose(true)}
                >
                  Save & Close
                </Button>
              ) : (
                <IconButton
                  variant="falcon-primary"
                  icon={isRTL ? 'chevron-right' : 'chevron-left'}
                  iconAlign="left"
                  transform="down-1 shrink-4"
                  className="m-1 ps-2 pe-2"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </IconButton>
              )}
              {step === 6 ? (
                <></>
              ) : step === 5 ? (
                <Button
                  variant="primary"
                  className="ps-2 pe-2 m-1"
                  type="submit"
                  transform="down-1 shrink-4"
                >
                  Submit
                </Button>
              ) : (
                <IconButton
                  variant="primary"
                  className="m-1"
                  type="submit"
                  icon={isRTL ? 'chevron-left' : 'chevron-right'}
                  iconAlign="right"
                  transform="down-1 shrink-4"
                >
                  Next
                </IconButton>
              )}{' '}
            </div>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: index < 6 ? step > index : step > 5,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemCompletion = ({ step }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > 5,
          active: step === 6
        })}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon="check" />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">Completed</span>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

NavItemCompletion.propTypes = {
  step: PropTypes.number.isRequired
};

export default WizardLayout;
