import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const usePostImpact = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const postImpact = async (ImpactSimFormData, setImpactResponse) => {
    setLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/simulator/impact`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(ImpactSimFormData)
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setImpactResponse(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, postImpact };
};

export default usePostImpact;
