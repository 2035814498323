import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

const PolicyRadioInput = ({ name, checked, onChange }) => {

  const [currentChoice, setCurrentChoice] = useState(checked);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setCurrentChoice(newValue);
    onChange(newValue);
  };

  return (
    <Col md={12}>
      <Row className='align-items-center'>
        <Col xs={2} className='text-center m-0 p-0'>
          <span>None</span>
        </Col>
        <Col xs={6}>
          <div className="d-flex justify-content-between align-items-center">
            <label className="d-flex align-items-center">
              <input
                type="radio"
                name={name}
                checked={currentChoice === 0}
                value={0}
                onChange={handleChange}
                className="form-check-input me-2"
              />
              0
            </label>
            <label className="d-flex align-items-center">
              <input
                type="radio"
                name={name}
                checked={currentChoice === 1}
                value={1}
                onChange={handleChange}
                className="form-check-input me-2"
              />
              1
            </label>
            <label className="d-flex align-items-center">
              <input
                type="radio"
                name={name}
                checked={currentChoice === 2}
                value={2}
                onChange={handleChange}
                className="form-check-input me-2"
              />
              2
            </label>
            <label className="d-flex align-items-center">
              <input
                type="radio"
                name={name}
                checked={currentChoice === 3}
                value={3}
                onChange={handleChange}
                className="form-check-input me-2"
              />
              3
            </label>
          </div>
        </Col>
        <Col xs={4} className="text-center m-0 p-0">
          <span>Advanced</span>
        </Col>
      </Row>
    </Col>
  );
  // return (
  //   <Col md={12} style={{ border: '1px solid black'}}>
  //     <span className="policy-span-start">None</span>
  //     <span className="policy-span-end">Advanced</span>
  //     <div>
  //       {[0, 1, 2, 3].map(value => (
  //         <label className="policy-radio-label" key={value}>
  //           <input
  //             className="policy-radio-input"
  //             type="radio"
  //             name={name}
  //             checked={currentChoice === value}
  //             value={value}
  //             onChange={handleChange}
  //           />
  //           {value}
  //         </label>
  //       ))}
  //     </div>
  //   </Col>
  // );
};

PolicyRadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default PolicyRadioInput;
