import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import useGetOneClient from 'hooks/useGetOneClient';
import useIntake from 'hooks/useIntake';
import Avatar from 'components/common/Avatar';
import ClientWidjets from './ClientWidjets';
import useAccount from 'hooks/useAccount';

import '../../../assets/scss/theme/page-styles/_clientDetails.scss';
import IconButton from 'components/common/IconButton';

const InfoCard = ({ client }) => {
  const { returnAccount } = useAccount();
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const prospect = client.prospect;
  const creationDate = new Date(client.creationDate);
  const formattedDate = creationDate.toLocaleDateString('en-GB', {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  });

  const goToUserSettings = () => {
    navigate(`/clients/update/${client.id}`);
  };

  useEffect(() => {
    const loadUserInfo = async () => {
      const thisUser = await returnAccount();
      setUserInfo(thisUser);
    };

    loadUserInfo();
  }, [userInfo]);

  return (
    <Card className="client-details-card mt-3">
      <Card.Body className="client-details-card-body">
        {/* Header */}
        <div
          className={`client-details-card-header d-flex justify-content-between align-items-center ${
            prospect ? 'header-color-for-prospect' : 'header-color-for-client'
          }`}
        >
          {userInfo?.isAdmin && (
            <IconButton
              className="edit-button"
              variant="falcon-default"
              size="sm"
              icon="pencil-alt"
              onClick={goToUserSettings}
            >
              <span className="ms-1">Edit</span>
            </IconButton>
          )}
        </div>

        {/* Icon */}
        {/* <div className='client-industry-icon-div'>
                    <EnergyIndustryIcon className={`client-industry-icon industry-outer-circle industry-path-1 industry-path-2 ${prospect ? 'prospect-icon' : 'client-icon'}`}/>
                </div> */}

        {/* Content */}
        {/* <div className='client-details-card-content'>
                    <Card.Title>{client.name}</Card.Title>
                    <Card.Text>
                        {prospect ? 'Prospect since: ' : 'Client since: '}{formattedDate}
                    </Card.Text>
                </div> */}
        <div className="client-details-card-content">
          <Card.Title style={{ fontSize: '2rem' }}>{client.name}</Card.Title>
          <Card.Text>
            <span className="since-text-style">
              {prospect ? 'Prospect since: ' : 'Client since: '}
              {formattedDate}
            </span>
          </Card.Text>
        </div>
        <div className="info-card-basic-info">
          <div className="info-card-basic-column">
            <div className="info-card-basic-grid">
              <div className="grid-item">
                <FontAwesomeIcon
                  style={{ marginRight: '0.5rem' }}
                  icon="phone"
                />{' '}
                {client.phone}
              </div>
              <div className="grid-item">
                <FontAwesomeIcon
                  style={{ marginRight: '0.5rem' }}
                  icon="envelope"
                />{' '}
                {client.email}
              </div>
              <div className="grid-item">
                <FontAwesomeIcon
                  style={{ marginRight: '0.5rem' }}
                  icon="globe"
                />{' '}
                {client.website}
              </div>
              <div className="grid-item">
                <FontAwesomeIcon
                  style={{ marginRight: '0.5rem' }}
                  icon="map-pin"
                />{' '}
                {client.address}
              </div>
            </div>
          </div>
          <div className="info-card-basic-column">
            <h5 className="basic-info-contact-header">Primary Contact</h5>
            <div className="primary-contact-info-container">
              {client.contacts[0] ? (
                <>
                  <Avatar
                    size="3xl"
                    className="primary-contact-icon"
                    name={client.contacts[0].name}
                  />
                  <div className="primary-contact-info-details">
                    <div className="fw-bold">{client.contacts[0].name}</div>
                    <div className="">
                      <FontAwesomeIcon
                        style={{ marginRight: '0.5rem' }}
                        icon="envelope"
                      />{' '}
                      {client.contacts[0].contactMethod === 'email' ? (
                        <>
                          <a href={`mailto:${client.contacts[0].email}`}>
                            {client.contacts[0].email}
                            <FontAwesomeIcon
                              style={{ marginLeft: '0.5rem' }}
                              icon="star"
                            />
                          </a>
                        </>
                      ) : (
                        client.contacts[0].email
                      )}
                    </div>
                    <div className="">
                      <FontAwesomeIcon
                        style={{ marginRight: '0.5rem' }}
                        icon="phone"
                      />{' '}
                      {client.contacts[0].contactMethod === 'phone' ? (
                        <>
                          <a href={`tel:${client.contacts[0].phone}`}>
                            {client.contacts[0].phone}
                            <FontAwesomeIcon
                              style={{ marginLeft: '0.5rem' }}
                              icon="star"
                            />
                          </a>
                        </>
                      ) : (
                        client.contacts[0].phone
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <p>No info available</p>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

InfoCard.propTypes = {
  client: PropTypes.object.isRequired
};

const ProspectConvert = ({ clientId }) => {
  const clientLimit = localStorage.getItem('clientLimit');

  const navigate = useNavigate();
  const { upgradeClient } = useGetOneClient();

  const handleConvertToClient = () => {
    const clientUpdates = { isProspect: false };
    upgradeClient(clientId, clientUpdates)
      .then(updatedClient => {
        // Handle successful update (e.g., show a success message, redirect, etc.)
      })
      .catch(error => {
        console.error('Error updating client:', error);
        // Handle error (e.g., show an error message)
      });

    navigate(`/clients`);
  };

  return (
    <Card className="mt-3">
      <Card.Body className="text-center d-flex flex-column justify-content-center align-items-center">
        {clientLimit === 'true' ? (
          <>
            <div className="fw-bolder">
              You have reached your limit of how many Clients you may have.
              Please upgrade your plan to convert this Prospect
            </div>
          </>
        ) : (
          <>
            <div className="fw-bolder">
              Convert from a Prospect to a Client to unlock all features,
              including easy report access and controls!
            </div>
            <Button
              className="mt-3"
              variant="success"
              onClick={handleConvertToClient}
            >
              Convert To Client
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

ProspectConvert.propTypes = {
  clientId: PropTypes.string.isRequired
};

const ClientDetails = () => {
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [intake, setIntake] = useState(null);
  const { getClient } = useGetOneClient();
  const { fetchIntakeForClient } = useIntake();
  const navigate = useNavigate();

  const goToIntakeForm = () => {
    navigate(`/clients/${client.id}/intake`);
  };

  useEffect(() => {
    const getClientDetails = async clientId => {
      try {
        const clientDetail = await getClient(clientId);

        if (!clientDetail.prospect) {
          getIntakeDetails();
        } else {
          setIntake(null);
        }

        setClient(clientDetail);
      } catch (error) {
        throw new Error(error.message);
      }
    };

    const getIntakeDetails = async () => {
      try {
        const clientIntake = await fetchIntakeForClient(clientId);

        setIntake(clientIntake);
      } catch (error) {
        throw new Error(error.message);
      }
    };

    localStorage.setItem('clientId', clientId);
    getClientDetails(clientId);
  }, []);

  const backToOverview = () => {
    navigate(-1);
  };

  if (!client) return <div>Loading...</div>;

  return (
    <>
      <div className="mt-3 fw-bold">
        <a onClick={backToOverview} className="return-to-overview">
          <FontAwesomeIcon icon="chevron-left" className="me-3" />
          Back to Clients
        </a>
      </div>
      <InfoCard client={client} />
      {client.prospect ? <ProspectConvert clientId={clientId} /> : <></>}
      <ClientWidjets
        client={client}
        intake={intake}
        goToIntakeForm={goToIntakeForm}
      />
    </>
  );
};

export default ClientDetails;
