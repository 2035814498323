import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import paths from 'routes/paths';
import Spinner from 'react-bootstrap/Spinner';

const SignUp = () => {
  const { loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    const handleSignUp = async () => {
      await logout({ returnTo: window.location.origin });
      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup'
        },
        appState: {
          returnTo: paths.landing
        }
      });
    };

    handleSignUp();
  }, [loginWithRedirect, logout]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
      }}
    >
      <Spinner animation="border" size="xl" role="Status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default SignUp;
