import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import lightLogo from 'assets/img/threat-captain-logos/cap-and-blue-title.svg';
import darkLogo from 'assets/img/threat-captain-logos/dark-cap-and-blue-title.svg';

const Logo = ({ at = 'auth', width = 100, className, ...rest }) => {
  const { config: { isDark } } = useAppContext();

  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-4 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img className="me-2" src={isDark ? darkLogo : lightLogo} alt="Logo" width={225} />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string
};

export default Logo;
