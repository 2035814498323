import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row, Form } from 'react-bootstrap';

const PrimaryContactForm = ({
  register,
  errors,
  setValue,
  client,
  updateClient
}) => {
  const contactMethod = client?.contacts[0]?.contactMethod || 'email';

  useEffect(() => {
    if (client) {
      if (client.contacts[0]) {
        setValue('contactName', client.contacts[0].name || '');
        setValue('contactPhone', client.contacts[0].phone || '');
        setValue('contactEmail', client.contacts[0].email || '');
        setValue('contactMethod', contactMethod);
      }
    }
  }, [location.search, setValue, client]);

  const handleInputChange = (field, value) => {
    if (client && client.contacts && client.contacts.length > 0) {
      const updatedClient = {
        ...client,
        contacts: [
          {
            ...client.contacts[0],
            [field]: value
          }
        ]
      };
      updateClient(updatedClient); // Pass the updated client object to the parent or store
    }
  };

  return (
    <>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            label="Contact Name"
            name="contactName"
            required
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactName', {
                required: 'Contact Name is required',
                pattern: {
                  value: /^[a-z ,.'-]+$/i,
                  message: 'Please enter the Company Name'
                },
                onChange: e => handleInputChange('name', e.target.value)
              })
            }}
          />
        </Col>
        <Col md="6">
          <Form.Label>Preferred Contact Method</Form.Label>
          <br />
          <WizardInput
            name="contactMethodEmail"
            type="radio"
            label="Contact via Email"
            value="email"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactMethod')
            }}
          />
          <WizardInput
            name="contactMethodPhone"
            type="radio"
            label="Contact via Phone"
            value="phone"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactMethod')
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            label="Contact Phone #"
            name="contactPhone"
            required
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactPhone', {
                required: 'Contact Phone is required',
                pattern: {
                  value: /^\d{3}[-.\s]?\d{3}[-.\s]?\d{4}$/,
                  message: 'Phone number must be a valid 10 digit format'
                },
                onChange: e => handleInputChange('phone', e.target.value)
              })
            }}
          />
        </Col>
        <Col md={6}>
          <WizardInput
            type="email"
            errors={errors}
            label="Contact Email"
            name="contactEmail"
            required
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactEmail', {
                required: 'Contact Email is required',
                pattern: {
                  value:
                    /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                  message: 'Email must be valid'
                },
                onChange: e => handleInputChange('email', e.target.value)
              })
            }}
          />
        </Col>
      </Row>
    </>
  );
};

PrimaryContactForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  client: PropTypes.object,
  updateClient: PropTypes.func.isRequired
};

export default PrimaryContactForm;
