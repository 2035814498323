import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { AuthContext } from 'components/auth0/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from 'react-bootstrap/Spinner';
// import BillingSlider from './BillingSlider';
import Greetings from './Greetings';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './billing.css';

// const plans = [
//   { index: 0, maxClients: 10, price: 399, title: 'Starter' },
//   { index: 1, maxClients: 25, price: 625, title: 'Growth' },
//   { index: 2, maxClients: 50, price: 900, title: 'Business' },
//   { index: 3, maxClients: 100, price: 1100, title: 'Enterprise' }
// ];
// const findPlan = maxClients =>
//   plans.find(plan => plan.maxClients === maxClients);

const schema = yup.object({
  name: yup.string().required('This field is required.'),
  email: yup.string().required('This field is required.'),
  phone: yup.string().required('This field is required.'),
  numClients: yup.number().required('This field is required.'),
  numEndpoints: yup.number().required('This field is required.'),
  message: yup.string()
});

const Billing = () => {
  const user = useContext(AuthContext);
  const subscription = user?.msp?.subscription ?? {};
  const hasSubscription =
    !!subscription.subscriptionId && subscription.status === 'ACTIVE';
  const selectedPlan = {
    maxClients: 10,
    price: 399,
    title: 'Basic'
  };
  // const [selectedPlan, setSelectedPlan] = useState(
  //   findPlan(subscription.maxClients)?.index ?? 0
  // );
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  // const currentPlan = findPlan(subscription.maxClients) ?? {
  //   maxClients: 0,
  //   price: 0,
  //   title: 'No Plan'
  // };
  const formRef = useRef(null);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const { getAccessTokenSilently } = useAuth0();
  const fetchRedirectURL = async () => {
    const maxClients = 10; //plans[selectedPlan].maxClients;
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      // Create a Checkout Session
      const res = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/subscription/create-checkout-session?maxClients=${maxClients}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      const data = await res.json();
      if (data.url) {
        window.location = data.url;
      } else if (data.success) {
        // Refresh the subscription in the BE (if the webhook hasn't been triggered yet) and update the user in the FE
        await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/subscription/refresh`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        toast.success('Subscription updated successfully.');
        // Would be better to update the user in the context without a reload
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred. Please try again later.');
    }
    setIsLoading(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (show) {
      document.getElementById('phone')?.focus();
    }
  }, [show]);

  const submitForm = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  const onSubmitCustomPricing = async data => {
    try {
      setShow(false);
      const accessToken = await getAccessTokenSilently();
      await toast.promise(
        fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/feedback/billing`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }),
        {
          pending: 'Sending message...',
          success: 'Request sent successfully.',
          error: 'An error occurred. Please try again later.'
        }
      );
    } catch (error) {
      console.error('Error sending billing email:', error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Greetings />
      {/* <Row className="px-4">
        <Col>
          <BillingSlider
            value={selectedPlan}
            onChange={setSelectedPlan}
            plans={plans}
          />
        </Col>
      </Row> */}
      <Row className="g-0 justify-content-between">
        <div>
          Please{' '}
          <a onClick={handleShow} className="btn btn-link p-0">
            contact us
          </a>{' '}
          for custom pricing options
        </div>
        {/* {hasSubscription && (
          <Col sm="6" className="m-0 p-2">
            <Card className="bg-100 shadow-none border mb-3">
              <Card.Body>
                <h4 className="text-primary fw-bold mb-0">Current Plan</h4>
                <PlanDetails plan={currentPlan} />
              </Card.Body>
            </Card>
          </Col>
        )} */}
        <Col sm="6" className="m-auto p-2">
          <Card className="bg-100 shadow-none border mb-3">
            <Card.Body>
              <h4 className="text-primary fw-bold mb-0">Current Plan</h4>
              <PlanDetails plan={selectedPlan} />
              {isLoading ? (
                <div className="mt-3 text-center w-100">
                  <Spinner animation="border" size="sm" role="Status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <Button
                  type="button"
                  onClick={fetchRedirectURL}
                  className="w-100 mt-3"
                  disabled={hasSubscription}
                >
                  Subscribe
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Custom pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body className="container-fluid">
          <Form
            ref={formRef}
            onSubmit={handleSubmit(onSubmitCustomPricing)}
            className="row g-3"
          >
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                defaultValue={user?.name}
                isInvalid={!!errors.name}
                {...register('name')}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                defaultValue={user?.email}
                isInvalid={!!errors.email}
                {...register('email')}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Phone #</Form.Label>
              <Form.Control
                type="tel"
                placeholder=""
                defaultValue={user?.phoneNumber}
                isInvalid={!!errors.phone}
                {...register('phone')}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6" controlId="numClients">
              <Form.Label>How many customers do you manage?</Form.Label>
              <Form.Control
                type="number"
                placeholder="10"
                isInvalid={!!errors.numClients}
                {...register('numClients')}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.numClients?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6" controlId="numEndpoints">
              <Form.Label>Number of Endpoints</Form.Label>
              <Form.Control
                type="number"
                isInvalid={!!errors.numEndpoints}
                {...register('numEndpoints')}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.numEndpoints?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                isInvalid={!!errors.message}
                {...register('message')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.message?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="button" variant="primary" onClick={submitForm}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const PlanDetails = ({ plan }) => {
  return (
    <div className="pt-2">
      <span className="price-per-client-plus">
        ${(plan.price / plan.maxClients).toFixed(2)}
      </span>
      <span className="price-client-label"> / client</span>
      <span className="price-month">${plan.price}</span>
      <span className="price-month-label"> / month</span>
      <p className="plan-description">
        Grants full access to ThreatCaptain, a multi-tenant cyber-risk platform
        designed for MSPs to comprehend the financial consequences of cyber
        breaches on clients and prospects.
      </p>
      <p>
        This monthly subscription supports up to{' '}
        <span className="fw-bold">{plan.maxClients}</span> clients.
      </p>
    </div>
  );
};

PlanDetails.propTypes = {
  plan: PropTypes.object.isRequired
};

export default Billing;
