import React, { useEffect, useState, useRef } from 'react';
import { Button, Dropdown, Form, Row, Col } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faEnvelope,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

import ConfirmDeletion from '../common/ConfirmDeletion';

import useGetUsers from 'hooks/useGetUsers';
import useUser from 'hooks/useUser';

import 'assets/scss/theme/page-styles/_userSettings.scss';

const UserManagement = ({ userInfo }) => {
  const { getUsers, isLoading, error, response } = useGetUsers();
  const {
    fetchUser,
    user: loggedInUser,
    addUser,
    deleteUser,
    sendInvite,
    reSendInvite
  } = useUser();
  const [selectedUser, setSelectedUser] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isCancelDisabled, setIsCancelDisabled] = useState(true);
  const [showNewUserInput, setShowNewUserInput] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [activeUserId, setActiveUserId] = useState(null);
  const iconRef = useRef(null);

  useEffect(() => {
    if (!hasFetched) {
      getUsers().finally(() => setHasFetched(true));
    }

    // Fetch the logged-in user only if it hasn't been fetched yet
    if (!loggedInUser) {
      fetchUser();
    }
  }, [hasFetched, getUsers, fetchUser, loggedInUser]);

  // console.log('Logged-out user:', loggedInUser);
  // Check if the logged-in user is not an admin
  const isAdmin = userInfo?.isAdmin;

  const handleIconClick = user => {
    setSelectedUser(user);
    setActiveUserId(user.id);
  };

  const handleResendLogin = async user => {
    await reSendInvite(user);
  };

  const handleDeleteUser = user => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleAddUserClick = () => {
    setShowNewUserInput(true);
    setIsSaveDisabled(false);
    setIsCancelDisabled(false);
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleSaveChanges = async () => {
    if (!validateEmail(email)) {
      setEmailError('Enter a valid email address');
      return;
    }
    setEmailError('');
    const newUser = await addUser({ email });
    await sendInvite(newUser);
    handleCancelClick();
    getUsers();
  };

  const handleCancelClick = () => {
    setShowNewUserInput(false);
    setIsSaveDisabled(true);
    setIsCancelDisabled(true);
    setEmail('');
    setEmailError('');
  };

  const validateEmail = email => {
    // Email validation regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedUser) {
      await deleteUser(selectedUser.id);
      handleClose();
      getUsers();
    }
  };

  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setActiveUserId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (isAdmin === undefined) {
    return null; // or a loading spinner if fetching user data
  }

  return (
    <>
      <ConfirmDeletion
        show={showModal}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        message={'Are you sure you want to delete this user?'}
      />
      <div className="container">
        <ToastContainer />
        <div className="user-settings-header d-flex flex-wrap align-items-center justify-content-between">
          <div className="d-flex align-items-center flex-wrap">
            <h5 className="me-11 mb-2 mb-md-0">Users</h5>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              className="m-13 ms-md-1"
              transform="shrink-3"
              style={{
                backgroundColor: '#2C7BE5',
                borderColor: '#2C7BE5',
                color: 'white'
              }}
              onClick={handleAddUserClick}
            >
              <span className="ms-1">Add User</span>
            </IconButton>
          </div>
        </div>
        {showNewUserInput && (
          <>
            <Row className="g-3 mb-3">
              <Col className="col-6">
                <Form.Group className="mb-3">
                  <Form.Label>New User Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                    isInvalid={!!emailError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="user-management-actions">
              <Col className="col-6">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSaveDisabled}
                  onClick={handleSaveChanges}
                >
                  Invite User
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  className="ms-2"
                  disabled={isCancelDisabled}
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </>
        )}
        {isLoading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {response && (
          <ul className="user-list">
            {response.map(user => (
              <li
                key={user.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{user.email}</span>
                  <Dropdown ref={iconRef}>
                    <Dropdown.Toggle
                      as="div"
                      className="custom-dropdown-toggle"
                      style={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                        border: 'none',
                        background: 'none',
                        padding: 0
                      }}
                    >
                      <div
                        className={`icon-square-box ${
                          activeUserId === user.id ? 'active' : ''
                        }`}
                        onClick={() => handleIconClick(user)}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} size="xs" />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item onClick={() => handleResendLogin(user)}>
                        <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                        Resend Login
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleDeleteUser(user)}
                        className="text-danger"
                      >
                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                        Delete User
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

UserManagement.propTypes = {
  userInfo: PropTypes.object.isRequired
};

export default UserManagement;
