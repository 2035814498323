import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import paths, { rootPaths } from 'routes/paths';

const ProfileDropdown = () => {
  const { user, logout } = useAuth0();

  if (!user) {
    return null;
  }

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin + paths.landing
      }
    });
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle bsPrefix="toggle" as="a" className="pe-0 ps-2 nav-link">
        <Avatar src={user.picture} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item className="fw-bold" href="#!">
            <span>{user.name}</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to={rootPaths.accountSettings}>
            <FontAwesomeIcon icon="cog" className="me-2" />{' '}
            <span>Settings</span>
          </Dropdown.Item>
          {/* <Dropdown.Item as={Link} to={rootPaths.billing}>
            <FontAwesomeIcon icon="cog" className="me-2" /> <span>Billing</span>
          </Dropdown.Item> */}
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to={rootPaths.feedback}>
            <FontAwesomeIcon icon="comments" className="me-2" />
            <span>Feedback</span>
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={`${rootPaths.accountSettings}?tab=support`}
          >
            <FontAwesomeIcon icon="question-circle" className="me-2" />
            <span>Help Center</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleLogout}>
            <FontAwesomeIcon icon="lock" className="me-1" /> <span>Logout</span>
          </Dropdown.Item>
          {/* <Dropdown.Item as={Link} to={paths.userProfile}>
            <FontAwesomeIcon icon="user" className="me-1" />
            <span>User Profile</span>
          </Dropdown.Item>{' '}
          <Dropdown.Item as={Link} to={paths.userProfile}>
            <FontAwesomeIcon icon="comment-dollar" className="me-1" />
            <span>Billing</span>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={paths.userSettings}>
            <FontAwesomeIcon icon="cog" className="me-1" />{' '}
            <span>Settings</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleLogout}>
            <FontAwesomeIcon icon="lock" className="me-1" /> <span>Logout</span>
          </Dropdown.Item> */}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
