import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row } from 'react-bootstrap';
import { industries } from 'data/simulators/impactSimulatorData';
import { timezones } from 'data/events/timezones';
import Select from 'react-select'; 


const GeneralForm = ({ register, errors, setValue, client }) => {
  useEffect(() => {
    if (client) {
      setValue('name', client.name || '');
      setValue('industry', client.industry || '');
      setValue('address', client.address || '');
      setValue('phone', client.phone || '');
      setValue('joinDate', client.joinDate || '');
      setValue('website', client.website || '');
      setValue('email', client.email || '');
      setValue('timezone', client.timezone || '');
    }
  }, [location.search, setValue, client]);

  const timezoneOptions = timezones.map(tz => ({
    label: tz, 
    value: tz 
  }));

  return (
    <>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            label="Company Name"
            name="name"
            required
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('name', {
                required: 'Company Name is required',
                pattern: {
                  // value: /^[A-Z]([a-zA-Z0-9]|[- @\\.#&!'"])*$/i,
                  value: /^[A-Z]([a-zA-Z0-9]|[- @\\.#&!',"/()+:;_%$*?^~|])*$/i,
                  message:
                    'Invalid Company Name. Company Name must start with a capital letter and contain only letters, numbers, and special characters (- @.#&!\'")'
                }
              })
            }}
          />
        </Col>
        <Col md={6}>
          <WizardInput
            type="select"
            label="Industry"
            name="industry"
            required
            options={industries}
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('industry', {
                required: 'Industry is required'
              })
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            label="Address"
            name="address"
            required
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('address', {
                required: 'Address is required'
              })
            }}
          />
        </Col>
        <Col md={6}>
          <WizardInput
            type="text"
            label="Website"
            name="website"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('website')
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            errors={errors}
            label="Company Phone #"
            name="phone"
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('phone')
            }}
          />
        </Col>
        <Col md={6}>
          <WizardInput
            type="email"
            errors={errors}
            label="Company Email"
            name="email"
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('email', {
                pattern: {
                  value:
                    /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                  message: 'Email must be valid'
                }
              })
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="date"
            errors={errors}
            label="Join Date"
            name="joinDate"
            required
            setValue={setValue}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('joinDate', {
                required: 'Join Date is required'
              })
            }}
          />
        </Col>
        <Col md={6}>
          
          <div className="mb-3">
            <label htmlFor="timezone" className="form-label">Timezone</label>
            <Select
              options={timezoneOptions}
              onChange={selectedOption => setValue('timezone', selectedOption.value)}
              value={timezoneOptions.find(option => option.value === client.timezone)}
              classNamePrefix="react-select"
            />
            {errors.timezone && <span className="text-danger">{errors.timezone.message}</span>}
          </div>
        </Col>
      </Row>
      <Row className="g-4 mb-3"></Row>
    </>
  );
};

GeneralForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  client: PropTypes.object
};

export default GeneralForm;
