import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row } from 'react-bootstrap';
import ClientStatItem from './ClientStatItem';

const ClientStats = ({ data }) => {
  return (
    <Card className="mb-3">
      <Card.Body className="px-xxl-0 pt-4">
        <Row className="g-0">
          {data.map(stat => (
            <ClientStatItem key={stat.id} stat={stat} />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

ClientStats.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ClientStats;
