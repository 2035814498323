import React, { useState, useEffect, useMemo } from 'react';
import Greetings from './greetings/Greetings';
import LikelihoodSimulatorForm from './LikelihoodSimulatorForm';
// import LikelihoodTable from './LikelihoodTable';
import LikelihoodGraph from './likelihood-graph/LikelihoodGraph';
import usePostLikelihood from 'hooks/usePostLikelihood';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LikelihoodSimulator = () => {
  const [simulationId, setSimulationId] = useState(null);
  const { postLikelihood } = usePostLikelihood();
  const [likelihoodResponse, setLikelihoodResponse] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (Object.keys(formData).length === 0) return;

  const fetchImpact = async (force = false) => {
    const toastId = toast.loading('Calculating likelihood...');
    try {
      await postLikelihood(
        formData,
        simulationId,
        setLikelihoodResponse,
        force
      );
      toast.dismiss(toastId);
      toast.success('Likelihood Completed', {
        autoClose: 5000,
      });
      window.scrollTo(document.getElementById('likelihood-result'));
    } catch (error) {
      toast.dismiss(toastId);
      if (error.status === 422) {
        // TODO: use a better confirm dialog
        if (
          window.confirm(
            'The impact simulation was not run for this client. The values will be inaccurate. Are you sure you want to continue?'
          )
        ) {
          fetchImpact(true);
        }
      } else {
        toast.error('Likelihood Failed', {
          autoClose: 5000,
        });
        toast.update(toastId, {
          render: 'An error occurred. Please try again later.',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      }
    }
  };
    fetchImpact();
  }, [formData]);

  const residualRisk = useMemo(() => {
    if (!likelihoodResponse) return null;
    const resultData = likelihoodResponse.forecasts[0]?.resultData;
    return resultData[0][1].avgBreachCost * resultData[0][1].totalSuccessRatio;
  }, [likelihoodResponse]);

  const riskDelta = useMemo(() => {
    if (!likelihoodResponse) return null;
    const resultData = likelihoodResponse.forecasts[0]?.resultData;
    const result = {};
    likelihoodResponse.controlGroups.forEach(controlGroup => {
      result[controlGroup.name] =
        (controlGroup.blockScore / resultData[0][1].totalAggregateBlockscore) *
        (resultData[0][1].avgBreachCost *
          (1 - resultData[0][1].totalSuccessRatio));
    });
    return result;
  }, [likelihoodResponse]);

  return (
    <>
      <ToastContainer />
      <Greetings />
      <LikelihoodSimulatorForm
        simulationId={simulationId}
        setSimulationId={setSimulationId}
        setFormData={setFormData}
        hasResult={likelihoodResponse !== null}
      />
      <div id="likelihood-result">
        <LikelihoodGraph residualRisk={residualRisk} riskDelta={riskDelta} />
      </div>
    </>
  );
};

export default LikelihoodSimulator;
