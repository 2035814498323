import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Row } from 'react-bootstrap';
import {
  companySecurityOptions,
  riskToleranceOptions,
  securityPostureOptions,
  complianceOptions
} from 'data/clients/intakeWizardData';

const BusinessForm = ({ register, errors, intake, setValue }) => {

  useEffect(() => {
    if (intake) {
      setValue('companySecurity', intake.companySecurity || '');
      setValue('riskTolerance', intake.riskTolerance || '');
      setValue('securityPosture', intake.securityPosture || '');
      setValue('compliance', intake.compliance || '');
    }
  }, [intake, setValue]);

  return (
    <>
      <h4>Business Questions</h4>
      <Row className="g-4 mb-3">
        <p>
          Typically the Business wants to better understand the likelihood and
          impact of cybercrime on their organization. Cybersecurity uses
          training as part of their approved compliance and risk mitigation
          strategy to meet that need.
        </p>
      </Row>
      <WizardInput
        type="select"
        label="How secure is your company?"
        name="companySecurity"
        errors={errors}
        options={companySecurityOptions}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('companySecurity')
        }}
      />

      <WizardInput
        type="select"
        label="What is your Risk Tolerance?"
        name="riskTolerance"
        errors={errors}
        options={riskToleranceOptions}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('riskTolerance')
        }}
      />

      <WizardInput
        type="select"
        label="What is your Security Posture?"
        name="securityPosture"
        errors={errors}
        options={securityPostureOptions}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('securityPosture')
        }}
      />
      <WizardInput
        type="select"
        label="What is your need for Compliance?"
        name="compliance"
        errors={errors}
        options={complianceOptions}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('compliance')
        }}
      />
    </>
  );
};

BusinessForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  intake: PropTypes.object.isRequired
};

export default BusinessForm;
