import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PolicyRadioInput from './PolicyRadioInput';

const PoliciesForm = ({ register, intake, setValue }) => {

  useEffect(() => {
    if (intake) {
      setValue('pepp', intake.pepp || 0); setValue('ipppp', intake.ipppp || 0); setValue('cmp', intake.cmp || 0);
      setValue('aap', intake.aap || 0); setValue('pmp', intake.pmp || 0); setValue('saap', intake.saap || 0);
      setValue('bep', intake.bep || 0); setValue('rap', intake.rap || 0); setValue('iap', intake.iap || 0);
      setValue('siip', intake.siip || 0); setValue('mpp', intake.mpp || 0); setValue('pp', intake.pp || 0);
      setValue('gp', intake.gp || 0); setValue('atp', intake.atp || 0); setValue('acp', intake.acp || 0);
      setValue('scpp', intake.scpp || 0); setValue('cpp', intake.cpp || 0); setValue('psp', intake.psp || 0);
      setValue('scrm', intake.scrm || 0); setValue('ip', intake.ip || 0); setValue('irp', intake.irp || 0);
    }
  }, [intake, setValue]);

  const handleRadioChange = (name) => (value) => {
    setValue(name, value);
  };

  return (
    <>
      <h4>Security Policies</h4>
      <Row className="g-4 mb-3">
        <p>Rate the current policies on a scale from 0 - 3.</p>
      </Row>
      <Row className="g-4 mb-3">
        {/* column 1 */}
        <Col md={4}>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Physcial & Environmental Protection <br />
                Policy (Protect)
              </h5>
            </Flex>
            <p className="fs-10">Secures physical assets and infrastructure.</p>

            <PolicyRadioInput
              name="pepp"
              checked={intake.pepp || 0}
              onChange={handleRadioChange('pepp')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Information Protection Processes & Procedures Policy (Protect)
              </h5>
            </Flex>
            <p className="fs-10">
              Establishes protective measures for data and information systems.
            </p>

            <PolicyRadioInput
              name="ipppp"
              checked={intake.ipppp || 0}
              onChange={handleRadioChange('ipppp')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Configuration Management Policy (Protect, Identify)
              </h5>
            </Flex>
            <p className="fs-10">Manages system configurations and changes.</p>

            <PolicyRadioInput
              name="cmp"
              checked={intake.cmp || 0}
              onChange={handleRadioChange('cmp')}
              
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Audit & Accountability Policy (Detect)
              </h5>
            </Flex>
            <p className="fs-10">Monitors and records IT system activities.</p>

            <PolicyRadioInput
              name="aap"
              checked={intake.aap || 0}
              onChange={handleRadioChange('aap')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Program Management Policy (Identify)
              </h5>
            </Flex>
            <p className="fs-10">
              Provides and overaarching framework for cybersecurity management.
            </p>

            <PolicyRadioInput
              name="pmp"
              checked={intake.pmp || 0}
              onChange={handleRadioChange('pmp')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Security Assessment & Authorization Policy (Identify)
              </h5>
            </Flex>
            <p className="fs-10">
              Evaluates and authorizes system security pre-activation
            </p>

            <PolicyRadioInput
              name="saap"
              checked={intake.saap || 0}
              onChange={handleRadioChange('saap')}
            />
          </Row>
          <Row>
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Business Environment Policy (Identify)
              </h5>
            </Flex>
            <p className="fs-10">
              Aligns cybersecurity strategies with business objectives.
            </p>

            <PolicyRadioInput
              name="bep"
              checked={intake.bep || 0}
              onChange={handleRadioChange('bep')}
            />
          </Row>
        </Col>
        {/* column 2 */}
        <Col md={4}>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Risk Assessment Policy (Identify)
              </h5>
            </Flex>
            <p className="fs-10">
              Identifies and evaluates risks to the organization's information
              systems and data.
            </p>

            <PolicyRadioInput
              name="rap"
              checked={intake.rap || 0}
              onChange={handleRadioChange('rap')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Identification & Authentication Policy (Protect)
              </h5>
            </Flex>
            <p className="fs-10">
              Verifies user and device identities before system access.
            </p>

            <PolicyRadioInput
              name="iap"
              checked={intake.iap || 0}
              onChange={handleRadioChange('iap')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                System & Information Integrity Policy (Protect)
              </h5>
            </Flex>
            <p className="fs-10">
              Prevents unauthorized system and data modifications.
            </p>

            <PolicyRadioInput
              name="siip"
              checked={intake.siip || 0}
              onChange={handleRadioChange('siip')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Media Protection Policy (Protect)
              </h5>
            </Flex>
            <p className="fs-10">
              Handles and disposes of media with sensitive data.
            </p>

            <PolicyRadioInput
              name="mpp"
              checked={intake.mpp || 0}
              onChange={handleRadioChange('mpp')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">Privacy Policy (Protect)</h5>
            </Flex>
            <p className="fs-10">
              Ensures compliance with privacy laws and regulations.
            </p>

            <PolicyRadioInput
              name="pp"
              checked={intake.pp || 0}
              onChange={handleRadioChange('pp')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Governance Policy
                <br />
                (Identify)
              </h5>
            </Flex>
            <p className="fs-10">
              Sets up compliance structures and strategic alignment processes.
            </p>

            <PolicyRadioInput
              name="gp"
              checked={intake.gp || 0}
              onChange={handleRadioChange('gp')}
            />
          </Row>
          <Row>
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Awareness and Training Policy (Protect)
              </h5>
            </Flex>
            <p className="fs-10">
              Educates employees on cybersecurity risks and policy adherence.
            </p>

            <PolicyRadioInput
              name="atp"
              checked={intake.atp || 0}
              onChange={handleRadioChange('atp')}
            />
          </Row>
        </Col>
        {/* column 3 */}
        <Col md={4}>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Access Control Policy (Protect)
              </h5>
            </Flex>
            <p className="fs-10">
              Controls who can access organization resources and data.
            </p>

            <PolicyRadioInput
              name="acp"
              checked={intake.acp || 0}
              onChange={handleRadioChange('acp')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                System & Communications Protection Policy (Protect)
              </h5>
            </Flex>
            <p className="fs-10">
              Protects the organization's networks and communication systems.
            </p>

            <PolicyRadioInput
              name="scpp"
              checked={intake.scpp || 0}
              onChange={handleRadioChange('scpp')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Contingency Planning Policy (Recover)
              </h5>
            </Flex>
            <p className="fs-10">
              Plans for operational restoration after a cybersecurity incident.
            </p>

            <PolicyRadioInput
              name="cpp"
              checked={intake.cpp || 0}
              onChange={handleRadioChange('cpp')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Personnel Security Policy (Protect)
              </h5>
            </Flex>
            <p className="fs-10">Manages employee access to sensitive data. </p>

            <PolicyRadioInput
              name="psp"
              checked={intake.psp || 0}
              onChange={handleRadioChange('psp')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Supply Chain Risk Management (Identify)
              </h5>
            </Flex>
            <p className="fs-10">
              Manages risks from external suppliers and vendors.
            </p>

            <PolicyRadioInput
              name="scrm"
              checked={intake.scrm || 0}
              onChange={handleRadioChange('scrm')}
            />
          </Row>
          <Row className="mb-7">
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Improvements Policy (Detect, Respond, Recover)
              </h5>
            </Flex>
            <p className="fs-10">
              Focuses on continuous improvement in detection, response, and
              recovery strategies.
            </p>

            <PolicyRadioInput
              name="ip"
              checked={intake.ip || 0}
              onChange={handleRadioChange('ip')}
            />
          </Row>
          <Row>
            <Flex className="mb-3">
              <h5 className="fs-9 text-600 mb-0">
                Incident Response Policy (Respond){' '}
              </h5>
            </Flex>
            <p className="fs-10">
              Outlines steps for handling security breaches or attacks.
            </p>

            <PolicyRadioInput
              name="irp"
              checked={intake.irp || 0}
              onChange={handleRadioChange('irp')}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

PoliciesForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object,
  intake: PropTypes.object
};

export default PoliciesForm;
