import React from 'react';
import { Row } from 'react-bootstrap';
import AddClientHeader from './IntakeHeader';
import Wizard from './addClientWizard/Wizard';

const AddClient = () => {
  return (
    <>
      <Row className="g-3">
        <AddClientHeader />
      </Row>
      <Row className="g-3">
        <Wizard validation={true} />
      </Row>
    </>
  );
};

export default AddClient;
