import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { rootPaths } from 'routes/paths';
import Avatar from 'components/common/Avatar';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';
import useMsp from 'hooks/useMsp'; // Import the custom hook
import 'react-toastify/dist/ReactToastify.css';

const MspSettings = ({ mspDetails, setMspDetails }) => {
  const { user } = useAuth0();
  const { updateMspDetails } = useMsp();

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zipcode: ''
  });

  const formattedMspDetails = useMemo(() => {
    if (mspDetails) {
      return {
        name: mspDetails.name || '',
        address: mspDetails.address || '',
        city: mspDetails.city || '',
        state: mspDetails.state || '',
        zipcode: mspDetails.zipcode || ''
      };
    }
    return {};
  }, [mspDetails]);

  useEffect(() => {
    if (formattedMspDetails) {
      setFormData({
        name: formattedMspDetails.name,
        address: formattedMspDetails.address,
        city: formattedMspDetails.city,
        state: formattedMspDetails.state,
        zipcode: formattedMspDetails.zipcode
      });
    }
  }, [formattedMspDetails]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await updateMspDetails(formData);
    if (response) {
      setMspDetails(response); // Update the parent component's state
    }
  };

  if (!mspDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ToastContainer />
      <div className="general-settings-top-section">
        {mspDetails.isAdmin ? <p>Admin</p> : <p>User</p>}
        {user?.picture ? (
          <Avatar size="3xl" src={user.picture} />
        ) : (
          <Avatar size="3xl" name={mspDetails.name} />
        )}
      </div>
      <Form onSubmit={handleSubmit} className="account-general-details-form">
        <Row>
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formState">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col md={6}>
            <Form.Group controlId="formZipcode">
              <Form.Label>Zipcode</Form.Label>
              <Form.Control
                type="text"
                name="zipcode"
                value={formData.zipcode}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4 justify-content align-items-center">
          <Col xs="auto">
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Col>
          <Col xs="auto">
            <Link
              className="cancel"
              to={rootPaths.root}
              style={{ color: 'black' }}
            >
              Cancel
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

MspSettings.propTypes = {
  mspDetails: PropTypes.object.isRequired,
  setMspDetails: PropTypes.func.isRequired
};

export default MspSettings;