import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import LikelihoodChart from './LikelihoodChart';

const LikelihoodGraph = ({ residualRisk, riskDelta, ...rest }) => {
  const formattedResidualRisk = residualRisk
    ? `${residualRisk.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })}`
    : 'N/A';
  return (
    <Card {...rest}>
      <FalconCardHeader
        title={
          <span>
            Annual Residual Risk: <strong>{formattedResidualRisk}</strong>
          </span>
        }
        titleTag="h5"
        className="py-3"
        light
      />
      <Card.Body className="px-0 pb-0">
        <LikelihoodChart riskDelta={riskDelta} />
      </Card.Body>
    </Card>
  );
};

LikelihoodGraph.propTypes = {
  residualRisk: PropTypes.number,
  riskDelta: PropTypes.object
};

export default LikelihoodGraph;
