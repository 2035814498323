import React, { useEffect } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import CallbackPage from 'components/pages/Callback';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import useUser from 'hooks/useUser';
import { useLocation } from 'react-router-dom';

const AuthenticationGuard = ({ component }) => {
  const { user, fetchUser, goToMsp } = useUser();
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  let billingRedirectLock = 0;

  useEffect(() => {
    const checkUser = async () => {
      if (isAuthenticated && !isLoading && !user) {
        await fetchUser();
      }

      if (
        isAuthenticated &&
        !isLoading &&
        user &&
        user?.msp === null &&
        goToMsp
      ) {
        navigate(paths.addMsp);
      }



      if (
        isAuthenticated && !isLoading && user && user.msp &&
        (user.msp.subscription == null || user.msp.subscription.status !== 'ACTIVE') && location.pathname !== paths.billing && billingRedirectLock == 0) {
        billingRedirectLock = 1;
        navigate(paths.billing);
        setTimeout(() => {
          billingRedirectLock = 0;
        }, 1000);
      }
    };
    checkUser();
  }, [isAuthenticated, isLoading, user, goToMsp]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <CallbackPage />
  });

  return (
    <AuthContext.Provider value={user}>
      <Component />
    </AuthContext.Provider>
  );
};

AuthenticationGuard.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

export default AuthenticationGuard;
