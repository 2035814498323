import FalconCardHeader from 'components/common/FalconCardHeader';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { ScatterChart, LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { rgbaColor } from 'helpers/utils';
import { useAppContext } from 'providers/AppProvider';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  ScatterChart,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (getThemeColor, data) => ({
  color: getThemeColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-100'),
    textStyle: { color: getThemeColor('gray-1100') },
    borderWidth: 1,
    formatter: params => {
      const { value } = params[0];
      return `<strong>Breach Cost: </strong>$${(value[0] / 1000000).toFixed(
        2
      )}M<br><strong>% of Simulated Outcomes: </strong>${value[1].toFixed(4)}%`;
    },
    transitionDuration: 0
  },
  xAxis: {
    type: 'value',
    name: 'Breach Cost (USD)',
    nameLocation: 'middle',
    nameTextStyle: {
      verticalAlign: 'bottom',
      padding: [0, 0, -35, 0],
      color: getThemeColor('gray-600')
    },
    boundaryGap: ['0%', '60%'],
    scale: true,
    onZero: false,
    axisPointer: {
      lineStyle: {
        color: getThemeColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: getThemeColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getThemeColor('gray-400'),
      formatter: value => `$${value / 1000000}M`,
      margin: 15
    }
  },
  yAxis: {
    name: '% of Simulated Outcomes',
    nameLocation: 'middle',
    nameTextStyle: {
      verticalAlign: 'bottom',
      padding: [0, 0, 35, 0],
      color: getThemeColor('gray-600')
    },
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getThemeColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getThemeColor('gray-400'),
      margin: 15,
      formatter: function (value) {
        return value.toFixed(2);
      }
    },
    axisTick: { show: false },
    axisLine: { show: false },
    min: 'dataMin',
    max: 'dataMax'
  },
  series: [
    {
      type: 'scatter',
      data,
      lineStyle: { color: getThemeColor('primary') },
      itemStyle: {
        borderColor: getThemeColor('primary'),
        borderWidth: 2
      },
      symbol: 'circle',
      symbolSize: 1,
      smooth: true,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getThemeColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getThemeColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: {
    right: 20,
    left: 25,
    bottom: 5,
    top: 10,
    containLabel: true,
    height: '90%'
  }
});
const ImpactCostGraph = ({ data }) => {
  const { getThemeColor } = useAppContext();
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Impact Simulator: Breach Cost"
        titleTag="h6"
        className="pb-0"
      />

      <Card.Body>
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(getThemeColor, data)}
          style={{ height: '19.4375rem' }}
        />
      </Card.Body>
    </Card>
  );
};

ImpactCostGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number).isRequired)
    .isRequired
};

export default ImpactCostGraph;
