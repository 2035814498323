import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import headCardGraph from 'assets/img/illustrations/reports-bg.png';
import reportGreeting from 'assets/img/illustrations/reports-greeting.png';
import sailorShip from 'assets/img/icons/sailor-ship.png';

const Reports = () => {
  return (
    <Container fluid className="d-flex flex-column min-vh-75">
      {/* Small rectangle */}
      <Row className="mb-3">
        <Col>
          <Card className="p-0">
            <Card.Body className="p-0">
              <Row noGutters>
                {/* Icon and Text */}
                <Col xs={8} className="d-flex align-items-center">
                  <div className="ps-3">
                    <img
                      src={reportGreeting}
                      width={60}
                      alt="..."
                      className="me-2"
                    />
                  </div>
                  <div>
                    <p className="m-0 text-primary fw-bold">Welcome to</p>
                    <h4 className="m-0">
                      <span className="text-primary fw-bold">THREAT</span>
                      <span className="text-info fw-bold">CAPTAIN</span> -
                      Reports
                    </h4>
                  </div>
                </Col>

                {/* End Graph */}
                <Col
                  xs={4}
                  className="d-flex align-items-stretch justify-content-end"
                >
                  <img
                    src={headCardGraph}
                    width={100}
                    height={'auto'}
                    alt="..."
                    className="ms-n4 d-md-none d-lg-block"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Big rectangle */}
      <Row className="flex-grow-1">
        <Col className="d-flex justify-content-center">
          <Card className="w-100">
            <Card.Body className="d-flex flex-column flex-md-row">
              <Col xs={12} md={6} className="d-flex align-items-center">
                <div className="w-100 text-center">
                  <img
                    src={sailorShip}
                    alt="Image"
                    className="img-fluid"
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                      verticalAlign: 'middle'
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className="d-flex align-items-center">
                <div className="w-100">
                  <h3 className="text-primary">
                    Reporting Feature Coming Soon!
                  </h3>
                  <p>
                    In the meantime, explore other features and leave us
                    feedback!
                  </p>
                  <Button
                    variant="falcon-primary"
                    as={Link}
                    to={paths.landing}
                  >
                    Home
                  </Button>
                </div>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Reports;
