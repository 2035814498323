import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useChangePass = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const changePass = async data => {
    setIsSubmitting(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/user/update-password`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(data)
        }
      );
      return response;
    } finally {
      setIsSubmitting(false);
    }
  };
  return { changePass, isSubmitting };
};

export default useChangePass;
