import { useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthContext } from 'components/auth0/AuthContext';

const useSaveClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const user = useContext(AuthContext);
  const mspId = user?.msp?.id;

  const saveClient = async data => {
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/clients/${mspId}/add`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify(data)
        }
      );

      if (!response.ok) {
        // Parse the error message
        if (response.status === 409) {
          try {
            const errorData = await response.json();
            return { status: response.status, error: errorData };
          } catch (error) {
            return { status: response.status, error: await response.text() };
          }
        } else {
          return { status: response.status, error: await response.text() };
        }
      }

      const responseData = await response.json();
      setResponse(responseData);
      //set client id in local storage
      localStorage.setItem('clientId', responseData.id);
      return { status: response.status, data: responseData };
    } catch (error) {
      return { status: 500, error: 'Network error or unexpected issue.' };
    } finally {
      setIsLoading(false);
    }
  };

  return { saveClient, isLoading, error, response };
};
export default useSaveClient;
