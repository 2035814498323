import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Col, Row, Form } from 'react-bootstrap';

const PrimaryContactForm = ({ register, errors }) => {
  return (
    <>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            label="Contact Name"
            name="contactName"
            required
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactName', {
                required: 'Contact Name is required',
                pattern: {
                  value: /^[a-z ,.'-]+$/i,
                  message: 'Please enter the Company Name'
                }
              })
            }}
          />
        </Col>
        <Col md="6">
          <Form.Label>Preferred Contact Method</Form.Label>
          <br />
          <WizardInput
            name="contactMethodEmail"
            type="radio"
            label="Contact via Email"
            value="email"
            checked={true}
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactMethod')
            }}
          />
          <WizardInput
            name="contactMethodPhone"
            type="radio"
            label="Contact via Phone"
            value="phone"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactMethod')
            }}
          />
        </Col>
      </Row>
      <Row className="g-4 mb-3">
        <Col md={6}>
          <WizardInput
            type="text"
            label="Contact Phone #"
            name="contactPhone"
            required
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactPhone', {
                required: 'Contact Phone is required',
                pattern: {
                  value: /^\d{3}[-.\s]?\d{3}[-.\s]?\d{4}$/,
                  message: 'Phone number must be a valid 10 digit format'
                }
              })
            }}
          />
        </Col>
        <Col md={6}>
          <WizardInput
            type="email"
            errors={errors}
            label="Contact Email"
            name="contactEmail"
            required
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('contactEmail', {
                required: 'Contact Email is required',
                pattern: {
                  value:
                    /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                  message: 'Email must be valid'
                }
              })
            }}
          />
        </Col>
      </Row>
    </>
  );
};

PrimaryContactForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default PrimaryContactForm;
