import React, { useContext, useEffect } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthContext } from 'components/auth0/AuthContext';
import useUser from 'hooks/useUser';
import { useNavigate } from 'react-router-dom';
import './AddMsp.css';
import paths from 'routes/paths';

const schema = yup
  .object({
    companyName: yup.string().required('This field is required.'),
    companyAddress: yup.string().required('This field is required.'),
    companyCity: yup.string().required('This field is required.'),
    companyState: yup.string().required('This field is required.'),
    companyZip: yup.string().required('This field is required.')
  })
  .required();

const AddMsp = () => {
  const user = useContext(AuthContext);
  const { isAddingMsp, addUserMsp } = useUser();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (user?.msp !== undefined && user?.msp !== null) {
      navigate(paths.dashboard);
    }
  }, [user, navigate]);

  const onSubmit = async data => {
    await addUserMsp({
      name: data.companyName,
      address: data.companyAddress,
      city: data.companyCity,
      state: data.companyState,
      zipcode: data.companyZip
    });
    // navigate(-1);
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="graphic-one">
          <img src="/img/circle-graphic-1.png" />
        </div>
        <div className="graphic-two">
          <img src="/img/circle-graphic-2.png" />
        </div>
        <div className="left-login-col">
          <img id="background-overlay" src="/img/half-circle-design.png" />
          <div className="login-message-inner">
            <img
              id="auth-logo"
              src="https://img1.wsimg.com/isteam/ip/5c27002e-0e59-4e6d-b50f-cd6896df6d8c/Threat-4%2520(2).png"
            />
            <p>
              Sail smoothly through cyber threats with ThreatCaptain's top-notch
              analytics and real-time insights for unbeatable security
              management.
            </p>

            <div id="bottomLeftText">
              <p>You are logged in as</p>
              <p style={{ fontWeight: 'bold' }}>{user?.name}</p>
              <p id="tos">
                Read our <a href="#">terms and conditions</a>
              </p>
            </div>
          </div>
        </div>
        <div className="right-login-col">
          <div id="loginForm">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h2>Account Setup</h2>
              <Row className="g-3 mb-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={!!errors.companyName}
                      {...register('companyName')}
                      placeholder="Company"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyName?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-3 mb-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={!!errors.companyAddress}
                      {...register('companyAddress')}
                      placeholder="Street Address"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyAddress?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-3 mb-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={!!errors.companyCity}
                      {...register('companyCity')}
                      placeholder="City"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyCity?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={!!errors.companyState}
                      {...register('companyState')}
                      placeholder="State"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyState?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Zipcode</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={!!errors.companyZip}
                      {...register('companyZip')}
                      placeholder="Zipcode"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyZip?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    type="submit"
                    variant="primary"
                    className="w-100"
                    disabled={isAddingMsp}
                  >
                    {isAddingMsp ? 'One Moment...' : 'Create'}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddMsp;
