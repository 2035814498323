import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.module.css';
import '../../../assets/scss/theme/miscellaneous/_datepickerOverrides.scss';

const CustomDateInput = forwardRef(
  (
    {
      value,
      onClick,
      isInvalid,
      isValid,
      formControlProps,
      errorMessage,
      className
    },
    ref
  ) => {
    return (
      <>
        <Form.Control
          ref={ref}
          isInvalid={isInvalid}
          isValid={isValid}
          value={value}
          onClick={onClick}
          autoComplete="off"
          {...formControlProps}
          className={`custom-datepicker ${className}`} // Apply custom styles here
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </>
    );
  }
);

const WizardInput = ({
  label,
  name,
  value,
  errors,
  checked,
  type = 'text',
  options = [],
  placeholder,
  formControlProps,
  formGroupProps,
  setValue,
  datepickerProps,
  required = false
}) => {
  const [date, setDate] = useState(null);
  const requiredAsterisk = required ? (
    <span style={{ color: 'red' }}>*</span>
  ) : null;

  if (type === 'date') {
    return (
      <Form.Group {...formGroupProps}>
        {!!label && (
          <Form.Label>
            {label} {requiredAsterisk}
          </Form.Label>
        )}

        <DatePicker
          required={required}
          selected={date}
          onChange={date => {
            setDate(date);
            setValue(name, date);
          }}
          customInput={
            <CustomDateInput
              className="custom-datepicker"
              formControlProps={formControlProps}
              errorMessage={errors[name]?.message}
              isInvalid={errors[name]}
              // isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
          }
          {...datepickerProps}
        />
      </Form.Group>
    );
  }

  if (type === 'checkbox' || type === 'switch' || type === 'radio') {
    return (
      <Form.Check
        type={type}
        id={name + Math.floor(Math.random() * 100)}
        inline
      >
        <Form.Check.Input
          required={required}
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
          value={value}
          checked={checked}
          // isValid={Object.keys(errors).length > 0 && !errors[name]}
        />
        <Form.Check.Label className="ms-2">
          {label} {requiredAsterisk}
        </Form.Check.Label>
        <Form.Control.Feedback type="invalid" className="mt-0">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Check>
    );
  }
  if (type === 'select') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>
          {label} {requiredAsterisk}
        </Form.Label>
        <Form.Select
          required={required}
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
          // isValid={Object.keys(errors).length > 0 && !errors[name]}
        >
          <option value="">{placeholder}</option>
          {options.map(option => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  if (type === 'textarea') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>
          {label} {requiredAsterisk}
        </Form.Label>
        <Form.Control
          required={required}
          as="textarea"
          placeholder={placeholder}
          {...formControlProps}
          // isValid={Object.keys(errors).length > 0 && !errors[name]}
          isInvalid={errors[name]}
          rows={4}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  return (
    <Form.Group {...formGroupProps}>
      <Form.Label>
        {label} {requiredAsterisk}
      </Form.Label>
      <Form.Control
        required={required}
        type={type}
        placeholder={placeholder}
        {...formControlProps}
        isInvalid={errors[name]}
        //isValid={Object.keys(errors).length > 0 && !errors[name]}
      />
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

CustomDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  formControlProps: PropTypes.object,
  errorMessage: PropTypes.string,
  className: PropTypes.string
};

WizardInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  setValue: PropTypes.func,
  datepickerProps: PropTypes.object,
  required: PropTypes.bool,
  checked: PropTypes.bool
};

export default WizardInput;
