import React, { createContext, useState, useContext } from "react";

const ClientListContext = createContext();

export const useFilter = () => {
    return useContext(ClientListContext);
};

export const ClientListProvider = ({ children }) => {
    const [filters, setFilters] = useState({ industry: 'All', prospect: undefined, timing: 'All' });

    return (
        <ClientListContext.Provider value={{ filters, setFilters }}>
            {children}
        </ClientListContext.Provider>
    );
};