import { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthContext } from 'components/auth0/AuthContext';

const useClients = () => {
  const [clients, setClients] = useState({});
  const { getAccessTokenSilently } = useAuth0();
  //replace this with a local storage call
  const user = useContext(AuthContext);

  const returnAccount = async () => {
    return user;
  };

  const getAccount = async (setUserInfo) => {
    setUserInfo(prevUser => ({
      ...prevUser,
      ...user
    }));
  };

  const updateAccount = async updatedAccount => {
    try {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
            `${process.env.REACT_APP_API_SERVER_URL}/user/update`,
          {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify(updatedAccount)
          }
        );
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        return response.status;

    } catch (error) {
        console.error('Error updating account:', error);

        return error.status || 500;
    }
  };

    return { getAccount, returnAccount, updateAccount };
};

export default useClients;
