import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const GenericError = ({ code, title, children }) => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">{code}</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semibold">
          {title}
        </p>
        <hr />
        <p>{children}</p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </Card.Body>
    </Card>
  );
};

GenericError.propTypes = {
  code: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default GenericError;
