import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Row, Form, Col } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import IntakeRecordsRange from './IntakeRecordsRange';
import {
  securityTeamOptions,
  breachHistoryOptions
} from 'data/clients/intakeWizardData';

const SecurityForm = ({ register, errors, control, intake, setValue }) => {
  const [recordVal, setRecordVal] = useState([0]);

  useEffect(() => {
    if (intake) {
      setRecordVal([intake.records]);
      setValue('securityTeam', intake.securityTeam || '');
      setValue('breachHistory', intake.breachHistory || '');
      setValue('bcplan', intake.bcplan || false);
      setValue('irplan', intake.irplan || false);
      setValue('drplan', intake.drplan || false);
      setValue('isia', intake.isia || false);
      setValue('cmplan', intake.cmplan || false);
      setValue('parm', intake.parm || false);
    }
  }, [intake, setValue]);
  
  return (
    <>
      <h4>Security Questions</h4>
      <Row className="g-4 mb-3">
        <p>
          These questions are all about gathering information on their current
          security posture. We use these questions to feed into the
          ThreatCaptain platform to run simulations on the Miter ATT&CK
          Framework Data and to also create a snapshot of your current security
          posture. These questions are not meant to replace a true and in-depth
          security audit but just guidance.
        </p>
      </Row>
      <WizardInput
        type="select"
        label="Tell us about your Security Team:"
        sublabel="What is the make up of the security team? Is there a CISO? How many people are on the team and who do they report to?"
        name="securityTeam"
        errors={errors}
        options={securityTeamOptions}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('securityTeam')
        }}
      />
      <Form.Group className="mb-3">
        <Form.Label className="fs-8">Records:</Form.Label>
        <br />
        <Form.Label className="fs-9">
          How many records would you estimate your organization is responsible
          for including all legacy, customer, partner, supply chain, and
          internal?
        </Form.Label>
        <Controller
          name="records"
          control={control}
          defaultValue={intake?.records || recordVal[0]}
          render={({ field }) => (
            <IntakeRecordsRange
              {...field}
              step={500000}
              max={10000000}
              min={0}
              values={recordVal}
              onChange={(val) => {
                setRecordVal(val);
                field.onChange(val[0]);
              }}
            />
          )}
        />
      </Form.Group>

      <WizardInput
        type="select"
        label="Breach History:"
        sublabel="Has your company experienced any breaches in the last 3 years?"
        name="breachHistory"
        errors={errors}
        options={breachHistoryOptions}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('breachHistory')
        }}
      />
      <Row className="g-4 mb-3">
        <Form.Group className="mb-3">
          <Form.Label className="fs-8">
            Critical Documents the Company Has - (Check all that apply)
          </Form.Label>
          <Col>
            <Row>
              <Col md={4}>
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="Buisness Continuity Plan (BCP)"
                  name="bcplan"
                  formControlProps={{
                    ...register('bcplan')
                  }}
                />
              </Col>
              <Col md={8}>
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="Incident Response Plan (IRP)"
                  name="irplan"
                  formControlProps={{
                    ...register('irplan')
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="Disaster Recovery Plan (DRP)"
                  name="drplan"
                  formControlProps={{
                    ...register('drplan')
                  }}
                />
              </Col>
              <Col md={8}>
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="Incident Severity and Impact Assessment"
                  name="isia"
                  formControlProps={{
                    ...register('isia')
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="Crisis Management Plan"
                  name="cmplan"
                  formControlProps={{
                    ...register('cmplan')
                  }}
                />
              </Col>
              <Col md={8}>
                <WizardInput
                  type="checkbox"
                  errors={errors}
                  label="Physical Asset Registry/Management"
                  name="parm"
                  formControlProps={{
                    ...register('parm')
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Form.Group>
      </Row>
    </>
  );
};

SecurityForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object
};

export default SecurityForm;
